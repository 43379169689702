.context {
  position: fixed;
  left: 24px;
  bottom: 24px;
  z-index: 999;
  width: 320px;
  padding: 32px 24px 24px;
  background: rgba(38, 38, 38, 0.95);
  border: 2px solid #292929;
  box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(10px);
  border-radius: 24px;

  &__caption {
    font-family: system-ui;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    letter-spacing: .5px; }

  &__title {
    margin-bottom: 12px;
    background: linear-gradient(90deg, #0162FF -8%, #CB36FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    font-weight: 600;
    color: transparent;
    font-size: 44px;
    line-height: 48px;
    letter-spacing: -0.02em; }

  &__text {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: $n3;
    span {
      font-weight: 500;
      color: $n1; } }

  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 44px;
    height: 44px;
    border: 2px solid rgba(white, .1);
    border-radius: 50%;
    font-size: 0;
    transition: border-color .2s;
    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23ADB7BE'%3E%3Cpath d='M6.988 5.585l.094.083L12 10.585l4.918-4.917a1 1 0 0 1 1.497 1.32l-.083.094L13.415 12l4.917 4.918a1 1 0 0 1-1.32 1.497l-.094-.083L12 13.415l-4.918 4.917a1 1 0 0 1-1.497-1.32l.083-.094L10.585 12 5.668 7.082a1 1 0 0 1 1.219-1.567l.101.069z'/%3E%3C/svg%3E");
      vertical-align: middle; }
    &:hover {
      border-color: $n1; } }

  .btn {
    padding-right: 16px;
    svg {
      width: 20px;
      height: 20px; } } }
