.users {
  padding: 32px;
  background: $n8;
  border-radius: 24px;
  @include t {
    margin: 0 -24px;
    padding: 32px 24px 80px;
    border-radius: 0; }

  &__inner {
    overflow: hidden; }

  &__list {
    margin-bottom: -48px; }

  &__foot {
    padding: 48px 0 8px;
    text-align: center; } }

.user {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $n7;
  @include m {
    flex-wrap: wrap; }

  &__cell {
    &:nth-child(1) {
      flex: 0 0 64px;
      margin-right: 20px;
      @include m {
        flex: 0 0 44px;
        margin: 0 12px 16px 0; } }
    &:nth-child(2) {
      flex: 1 1 auto;
      overflow: hidden;
      @include m {
        flex: 0 0 calc(100% - 56px);
        margin-bottom: 16px; } }
    &:nth-child(3) {
      margin-left: 48px;
      @include m {
        margin: 0; } }
    &:nth-child(4),
    &:nth-child(5) {
      margin-left: 24px;
      padding-left: 24px;
      border-left: 1px solid $n7;
      @include m {
        padding: 0;
        border: none; } }
    &:nth-child(4) {
      @include t {
        display: none; }
      @include m {
        display: block;
        margin-left: 32px; } }
    &:nth-child(5) {
      flex: 0 0 146px;
      @include t {
        flex: 0 0 68px; }
      @include m {
        flex: 0 0 44px;
        margin-left: auto; } } }
  &__cell:nth-child(2) &__content {
    @include m {
      display: none; } }

  &__avatar {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    @include m {
      width: 44px;
      height: 44px; }
    a {
      &:last-child {
        position: absolute;
        top: 0;
        left: 0; } } }

  &__title,
  &__content {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

  &__title {
    display: block;
    @include h6;
    color: $n1; }

  &__content {
    margin-top: 4px;
    @include body1;
    color: $n3; } }



