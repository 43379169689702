.toggle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include link-bold;
  color: $n5;
  transition: color .2s;
  user-select: none;
  cursor: pointer;
  &:hover {
    &:after {
      border-color: $n1; } }
  &.active {
    color: $green;
    &:hover {
      &:after {
        border-color: $n1; } }
    &:before {
      background: $green;
      transform: translateX(14px); }
    &:after {
      border-color: $green; } }

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    right: 19px;
    width: 14px;
    height: 14px;
    background: $n5;
    border-radius: 50%;
    transition: background .2s, transform .2s; }

  &:after {
    content: "";
    width: 38px;
    height: 24px;
    margin-left: 8px;
    background: $n9;
    border: 2px solid $n5;
    border-radius: 12px;
    transition: border-color .2s; } }
