.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  padding: 0 22px;
  background: $blue;
  border: 2px solid $blue;
  border-radius: 22px;
  @include base1-bold;
  color: $n1;
  transition: background .2s, border-color .2s, color .2s;
  &:not(:disabled):hover {
    background: $n1;
    border-color: $n1;
    color: $n7;
    svg {
      fill: $n7; } }
  &:disabled {
    opacity: .2;
    cursor: default; }
  svg {
    width: 24px;
    height: 24px;
    margin: 0 -2px 0 10px;
    fill: $n1; }

  &--green {
    background: $green;
    border-color: $green; }

  &--red {
    background: #C84131;
    border-color: #C84131; }

  &--stroke {
    background: none;
    border-color: $n6;
    color: $n3;
    &:not(:disabled):hover {
      background: none;
      border-color: $n1;
      color: $n3;
      svg {
        fill: $n3; } }
    svg {
      fill: $n3; } }

  &--icon {
    padding: 0 14px;
    &-pink {
      &:not(:disabled):hover {
        svg {
          fill: $pink; } }
      svg {
        fill: $pink; } }
    &-only {
      min-width: 44px;
      padding: 0;
      svg {
        margin: 0; } } }

  &--arrow {
    position: relative;
    padding-right: 46px;
    &:before,
    &:after {
      content: "";
      position: absolute; }
    &:before {
      top: 4px;
      right: 4px;
      width: 32px;
      height: 32px;
      background: $n6;
      border-radius: 50%; }
    &:after {
      top: 17px;
      right: 13px;
      width: 14px;
      height: 8px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8'%3E%3Cpath fill='%23adb7be' d='M11.796 0c.859 0 1.318 1.012.753 1.659L7.753 7.14a1 1 0 0 1-1.505 0L1.451 1.659C.885 1.012 1.345 0 2.204 0h9.592z'/%3E%3C/svg%3E"); } }

  &--like {
    svg {
      fill: transparent;
      transition: fill .2s; }
    path {
      stroke: $n3;
      transition: stroke .2s; }
    &:not(:disabled):hover {
      svg {
        fill: none; } }
    &.active {
      &:not(:disabled):hover {
        svg {
          fill: $red; } }
      svg {
        fill: $red; }
      path {
        stroke: $red; } } }

  &--follow {
    min-width: 122px;
    @include t {
      min-width: 44px;
      padding: 0;
      font-size: 0; }
    svg {
      display: none;
      @include t {
        display: block;
        margin: 0 -4px 0 0; } } }

  &--submit {
    display: flex;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    @include button-l;
    &:hover {
      svg {
        fill: $n7; } }
    svg {
      fill: $n1; } }

  &--back-admin {
    position: fixed;
    right: 88px;
    bottom: 20px;
    z-index: 9999999; } }
