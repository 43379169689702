.features {
  max-width: 1040px;
  margin: 0 auto 128px;
  @include m {
    display: none; }

  &__title {
    margin-bottom: 80px;
    @include h3;
    text-align: center; }

  &__row {
    display: flex;
    align-items: center;
    margin-bottom: 64px;
    @include t {
      margin-bottom: 40px; } }
  &__row:nth-child(2n+1) {
    flex-direction: row-reverse; }
  &__row:nth-child(2n+1) &__col {
    &:last-child {
      padding: 0 64px 0 0;
      @include t {
        padding: 0 40px 0 0; } } }

  &__col {
    flex: 0 0 50%;
    &:last-child {
      padding-left: 64px;
      @include t {
        padding-left: 40px; } } }

  &__preview {
    img {
      border-radius: 24px;
      @include t {
        height: 460px;
        object-fit: cover; } } }

  &__subtitle {
    margin-bottom: 20px;
    @include h5; }

  &__content {
    @include body1;
    color: $n3;
    p {
      &:not(:last-child) {
        margin-bottom: 12px; } } } }

