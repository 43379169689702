.footer {
  background: $n9;
  @include t {
    display: none; }

  &__container {
    position: relative;
    padding-top: 56px;
    padding-bottom: 36px; }

  &__top {
    position: absolute;
    right: 40px;
    bottom: calc(100% + 32px);
    @include d {
      right: 32px; } }

  &__row {
    display: flex; }

  &__col {
    &:nth-child(1) {
      margin-right: 48px; }
    &:nth-child(2) {
      margin-right: 48px; }
    &:nth-child(3) {
      margin-right: 104px; }
    &:nth-child(4) {
      width: 320px;
      margin-left: auto; } }

  &__title {
    margin-bottom: 16px;
    @include body2; }

  &__nav {
    margin: 40px 0 -24px;
    li {
      display: flex;
      margin-bottom: 24px; }
    a {
      @include link;
      color: $n3;
      transition: color .2s;
      &:hover {
        color: $n1; } }
    &--cols {
      display: flex;
      ul {
        margin-right: 56px;
        @include dmax {
          margin-right: 48px; }
        &:nth-child(2),
        &:nth-child(3) {
          @include d {
            display: none; } } } } }

  &__subscribe {
    min-height: 126px;
    margin-bottom: 48px;
    .field {
      &__label,
      &__input {
        background: $n9; }
      &__input {
        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 100px $n9 inset; } }
      .loader {
        position: absolute;
        top: 12px;
        right: 16px;
        z-index: 6;
        height: 24px;
        &:before {
          width: 24px;
          height: 24px; } } } }
  &__subscribe &__title {
    margin-bottom: 22px; }

  &__message {
    margin-top: -14px;
    @include link;
    color: $n3; }

  &__copyright {
    margin-top: 88px;
    @include caption2-medium;
    color: $n5; } }
