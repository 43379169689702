.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: 100%;
  padding: 24px 0;
  transition: background .2s;
  @include d {
    padding: 16px 0; }
  &.scrolled {
    background: rgba($n9,.98); }

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    height: 40px; }

  &__logo {
    position: relative;
    flex: 0 0 43px;
    height: 38px;
    margin-right: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='82' height='38'%3E%3Cpath fill='%232d68ff' d='M35.5 15.998A7.5 7.5 0 0 0 43 8.499a7.5 7.5 0 1 0-15 0 7.5 7.5 0 0 0 7.5 7.499z'/%3E%3Cpath fill='%23f8f8f8' fill-rule='nonzero' d='M36 20.939c0-.53-.462-.942-1-.942A11 11 0 0 1 24 8.999a10.96 10.96 0 0 1 .898-4.358c.281-.65.066-1.43-.562-1.772l-3.181-1.731a6.6 6.6 0 0 0-6.307 0l-11.4 6.201A6.6 6.6 0 0 0 0 13.137v11.736a6.6 6.6 0 0 0 3.446 5.798l11.4 6.201a6.6 6.6 0 0 0 6.307 0l11.4-6.201A6.6 6.6 0 0 0 36 24.873v-3.934zM55 13.5a1.5 1.5 0 1 0-3 0v7a5.5 5.5 0 1 0 11 0v-7a1.5 1.5 0 1 0-3 0v7a2.5 2.5 0 1 1-5 0v-7zm10.5 0a1.5 1.5 0 1 1 3 0v11a1.5 1.5 0 1 1-3 0v-11zM78 12a4 4 0 0 1 4 4 3.99 3.99 0 0 1-1.27 2.924c-.044.041-.044.111 0 .152A3.99 3.99 0 0 1 82 22a4 4 0 0 1-4 4h-3a4 4 0 0 1-4-4 3.99 3.99 0 0 1 1.27-2.924c.044-.041.044-.111 0-.152A3.99 3.99 0 0 1 71 16a4 4 0 0 1 4-4h3zm-.5 8.5h-2a1.5 1.5 0 1 0 0 3h0 2a1.5 1.5 0 1 0 0-3h0zm0-6h-2a1.5 1.5 0 1 0 0 3h0 2a1.5 1.5 0 1 0 0-3h0z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    @include m {
      margin-right: auto; } }

  &__group {
    display: flex;
    align-items: center;
    &--right {
      position: relative;
      margin: 0 -11px 0 auto;
      @include m {
        margin-left: 0; } } }

  &__cell {
    margin: 0 8px; }
  &__cell.active &__head,
  &__cell.active-touch &__head {
    color: $n1;
    @include m {
      color: $n3; }
    svg {
      fill: $n1;
      @include m {
        fill: $n3; } } }
  &__cell.active &__head {
    @include mouse {
      &:after {
        content: "";
        position: absolute;
        inset: -12px; } } }
  &__cell.active &__avatar,
  &__cell.active-touch &__avatar {
    &:after {
      border-color: $n1;
      @include m {
        border-color: $n6; } } }
  &__cell.active &__dropdown {
    @include mouse {
      display: block; }
    @include m {
      display: none; } }
  &__cell.active &__dropdown--browse {
    @include mouse {
      opacity: 1;
      pointer-events: all; } }
  &__cell.active-touch &__dropdown--browse {
    @include touch {
      opacity: 1;
      pointer-events: all; } }
  &__cell.active-touch &__dropdown {
    @include touch {
      display: block; }
    @include m {
      display: block; } }
  &__cell--renew {
    display: flex;
    align-items: center; }
  &__cell--cart.active &__dropdown {
    display: block; }
  &__cell--cart &__head {
    path {
      display: none;
      &.visible {
        display: block; } } }
  &__cell--cart &__dropdown {
    padding: 0;
    @include m {
      padding: 94px 24px 40px; } }
  &__cell--profile.only-mobile {
    display: none;
    @include m {
      display: block; } }
  &__cell--profile.only-mobile &__head {
    @include m {
      svg {
        width: 32px;
        height: 32px; } } }
  &__cell--profile.active &__head {
    @include mouse {
      &:after {
        left: -50%; } } }

  &__head {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    padding: 0 8px;
    @include link;
    color: $n3;
    transition: color .2s;
    user-select: none;
    cursor: pointer;
    @include m {
      padding: 0 4px; }
    &:hover {
      color: $n1;
      svg {
        fill: $n1; } }
    svg {
      width: 24px;
      height: 24px;
      fill: $n3;
      transition: fill .2s;
      @include m {
        width: 28px;
        height: 28px; } }
    &--sale {
      height: 26px;
      background: linear-gradient(155deg, #393540, #27232F);
      border-radius: 6px;
      &:hover {
        span {
          &:first-child {
            opacity: 0;
            animation-play-state: paused; }
          &:last-child {
            opacity: 1; } } }
      span {
        transition: opacity .2s;
        &:first-child {
          background: linear-gradient(to right, #FF78FA 20%, #FFB36C 40%, #FFB36C 60%, #FF78FA 80%);
          background-size: 200% auto;
          background-clip: text;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation: shine 2s linear infinite; }
        &:last-child {
          position: absolute;
          top: 4px;
          left: 8px;
          color: white;
          opacity: 0;
          @include m {
            left: 4px; } } } } }

  &__badge {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    padding: 2px 8px;
    border: 2px solid $green;
    border-radius: 13px;
    font-size: 12px;
    font-weight: 500;
    color: $green;
    letter-spacing: 0; }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 32px;
    height: 32px;
    background: $n6;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      inset: -5px;
      border: 2px solid $n6;
      border-radius: 50%;
      transition: border-color .2s; }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; } }

  &__circle {
    display: none;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 8px;
    height: 8px;
    background: $green;
    border-radius: 50%;
    @include m {
      top: 6px;
      right: 6px;
      width: 10px;
      height: 10px; }
    &.visible {
      display: block; } }

  &__dropdown {
    display: none;
    position: absolute;
    top: 52px;
    right: 11px;
    padding: 32px;
    background: $n7;
    border-radius: 24px;
    @include m {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      height: 100%;
      padding: 94px 24px 40px;
      border-radius: 0;
      transition: background .2s;
      overflow: auto; }
    &--browse {
      display: block;
      top: -24px;
      left: calc(50% - 50vw);
      right: auto;
      z-index: -1;
      width: 100vw;
      padding: 88px 0 24px;
      background: rgba($n9,.96);
      border-radius: 0;
      box-shadow: 0px 16px 16px rgba(black,.35);
      backdrop-filter: blur(16px);
      opacity: 0;
      pointer-events: none;
      transition: opacity .4s; }
    &--tools {
      width: 1012px;
      max-width: calc(100vw - 72px);
      padding: 16px; }
    &--notifications {
      width: 344px;
      max-height: 480px;
      overflow: auto;
      @include m {
        width: 100%;
        max-height: 100%; }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background: $n8;
          background-clip: content-box; } }
      &::-webkit-scrollbar {
        width: 16px; }
      &::-webkit-scrollbar-track {
        background: none; }
      &::-webkit-scrollbar-thumb {
        background: transparent;
        background-clip: content-box;
        border: solid transparent;
        border-width: 24px 6px;
        border-radius: 16px; } }
    &--profile {
      width: 232px;
      @include m {
        width: 100%; } } }
  &__dropdown.active &__panel {
      background: rgba($n9,.98); }

  &__panel {
    display: none;
    @include m {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 50;
      width: 100%;
      display: flex;
      align-items: center;
      height: 70px;
      padding: 0 24px;
      background: $n7;
      transition: background .2s; } }
  &__panel &__logo {
    @include m {
      margin-right: auto; } }

  &__close {
    width: 32px;
    height: 32px;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='20.5' x2='11.5' y1='11.5' y2='20.5' stroke='%23ADB7BE' stroke-width='2'/%3E%3Cline x1='11.5' x2='20.5' y1='11.5' y2='20.5' stroke='%23ADB7BE' stroke-width='2'/%3E%3Cpath stroke='%23ADB7BE' stroke-width='2' d='M31,16 C31,24.2843 24.2843,31 16,31 C7.71573,31 1,24.2843 1,16 C1,7.71573 7.71573,1 16,1 C24.2843,1 31,7.71573 31,16 Z'/%3E%3C/g%3E%3C/svg%3E");
    border: none; }

  .new {
    position: relative;
    width: 40px;
    height: 22px;
    margin-left: 8px;
    background: $n8;
    border-radius: 11px;
    overflow: hidden;
    &:before,
    &:after {
      content: "";
      position: absolute;
      inset: 0; }
    &:before {
      background: linear-gradient($n7, $n8);
      border-radius: 11px; }
    &:after {
      inset: 1px;
      background: $n8;
      border-radius: 10px; }
    &__stroke {
      position: absolute;
      top: 0;
      left: 0;
      width: 32px;
      height: 32px;
      background: #60D07A;
      border-radius: 50%;
      offset-path: path("M11 1H29C35 1 39 5 39 11C39 17 35 21 29 21H11C5 21 1 17 1 11C1 5 5 1 11 1Z");
      animation: new-stroke 4s linear infinite; }
    &__inner {
      position: absolute;
      inset: 1px;
      z-index: 2;
      border-radius: 10px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        inset: -1px;
        z-index: 1;
        box-shadow: inset 0 0 4px 4px $n8;
        border-radius: 10px; }
      span {
        position: absolute;
        inset: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: 500;
        color: #60D07A;
        &:nth-child(1) {
          animation: new-text-1 4s linear infinite; }
        &:nth-child(2) {
          animation: new-text-2 4s linear infinite; } } } } }

@keyframes new-stroke {
  0% {
    offset-distance: 100%; }
  100% {
    offset-distance: 0%; } }

@keyframes new-text-1 {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100%); } }

@keyframes new-text-2 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0%); } }

@keyframes shine {
  to {
    background-position: 200% center; } }
