.windows {
  .btn,
  .filters__btn,
  .layout__link {
    padding-bottom: 2px; }

  .btn {
    svg {
      margin-bottom: -1px; } }

  .notifications__icon {
    margin-top: 5px; } }
