.account {
  padding: 48px 56px;
  background: $n7;
  border-radius: 24px;
  @include m {
    margin: 0 -24px;
    padding: 32px 24px;
    border-radius: 0; }

  &__section {
    display: none;
    &.visible {
      display: block; } }

  &__head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }

  &__title {
    margin-bottom: 12px;
    @include h5; }

  &__content {
    margin-bottom: 24px;
    @include body1;
    color: $n3;
    p {
      margin-bottom: 12px; } }

  &__content + &__head {
    margin-top: 48px; }

  &__upload {
    margin-bottom: 24px; }

  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 144px;
    height: 144px;
    margin: 0 auto;
    background: $n8;
    border: 2px solid $n8;
    border-radius: 50%;
    transition: border-color .2s;
    &:hover {
      border-color: $n1;
      .btn {
        opacity: 1; } }
    svg {
      fill: $n3; }
    .btn {
      position: absolute;
      top: -2px;
      right: -2px;
      background: $n10;
      opacity: 0;
      transition: all .2s;
      &:hover {
        background: $n10; }
      @include touch {
        opacity: 1; } } }

  &__preview .loader,
  &__avatar,
  &__uploader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%; }

  &__avatar {
    background: $n8;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; } }

  &__uploader {
    cursor: pointer; }

  &__notifications {
    .toggle {
      padding: 10px 14px;
      border: 2px solid $n5;
      @include base2-medium;
      &:before {
        top: 15px;
        right: 33px; }
      &:not(:last-child) {
        margin-bottom: -2px; }
      &--first {
        border-radius: 8px 8px 0 0; }
      &--last {
        border-radius: 0 0 8px 8px; }
      &.active {
        color: $n1; } } }

  .field {
    margin-bottom: 26px;
    &__textarea {
      height: 70px; } }

  .mb-34 {
    margin-bottom: 34px; }

  &__foot {
    display: flex;
    margin: 48px -8px 0;
    .btn {
      flex: 1;
      margin: 0 8px; }
    .loader {
      flex: 1; } } }
