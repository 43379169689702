@mixin r($width) {
  @media only screen and (max-width: $width + "px") {
    @content; } }

@mixin dmax {
  @media only screen and (max-width: "1408px") {
    @content; } }
@mixin d {
  @media only screen and (max-width: "1259px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }

@mixin rh($height) {
  @media only screen and (max-height: $height + "px") {
    @content; } }

@mixin touch {
  @media (hover: none) {
    @content; } }

@mixin mouse {
  @media (hover: hover) {
    @content; } }

@mixin shadow {
  box-shadow: 0px 8px 80px rgba($n11, 0.4); }

@mixin hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; } }
