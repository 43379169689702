.feat {
  margin-bottom: 4px;

  &__item {
    display: flex;
    margin: 0 -2px 4px;
    @include t {
      display: block;
      position: relative; }
    &:nth-child(2) {
      flex-direction: row-reverse; } }

  &__preview {
    flex: 0 0 calc(33.33% - 4px);
    margin: 0 2px;
    aspect-ratio: 1;
    @include t {
      position: absolute;
      inset: 0;
      aspect-ratio: auto;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 100px);
        background: linear-gradient(transparent, $new-bg);
        border-radius: 16px 16px 0 0; }
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100px;
        background: $new-bg;
        border-radius: 0 0 16px 16px; } }
    img {
      border-radius: 16px;
      @include t {
        width: 100%;
        height: 100%;
        object-fit: cover; } } }

  &__inner {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 0 0 calc(66.66% - 4px);
    margin: 0 2px;
    padding: 32px 142px;
    background: $new-bg;
    border-radius: 16px;
    @include d {
      padding: 32px 48px; }
    @include t {
      padding: 142px 24px 32px;
      background: none; }
    @include m {
      padding-top: 216px; } }

  &__label {
    margin-bottom: 16px;
    padding: 4px 8px 6px;
    background: $n10;
    border-radius: 8px;
    @include link-book;
    color: $blue; }

  &__title {
    margin-bottom: 16px;
    @include h3;
    color: $n2; }

  &__text {
    @include sub-headline;
    color: $n3; } }

