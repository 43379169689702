.menu {
  &__inner {
    position: relative; }

  &__grid {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 348px;
    grid-template-rows: 1fr 116px;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    @include d {
      display: block; } }
  &__grid--overlay {
    position: absolute;
    inset: 0;
    pointer-events: none;
    user-select: none;
    opacity: var(--opacity, 0);
    mask: radial-gradient(320px 320px at var(--x) var(--y), black 1%, transparent 40%);
    will-change: mask;
    @include touch {
      display: none; } }
  &__grid--overlay &__categories {
    li {
      &:before {
        content: "";
        display: block;
        aspect-ratio: 1;
        border: 1px solid $blue;
        border-radius: 16px; } } }
  &__grid--overlay &__space {
    &:before {
      content: "";
      display: block;
      height: 100%;
      border: 1px solid $blue;
      border-radius: 16px; } }
  &__grid--overlay &__formats {
    border-color: $blue; }

  &__categories {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-area: 1 / 1 / 3 / 2;
    a {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      aspect-ratio: 1;
      padding: 1px;
      border: 1px solid $n7;
      border-radius: 16px;
      transition: background .4s;
      &:hover {
        background: #1B1B1B;
        svg {
          filter: grayscale(0); }
        span {
          &:nth-child(2) {
            color: $n1; }
          &:nth-child(3) {
            opacity: 1; } } } }
    svg {
      position: relative;
      width: 56px;
      height: 56px;
      margin: 0 auto 16px;
      will-change: filter;
      filter: grayscale(.9);
      transition: filter .4s; }
    span {
      &:nth-child(2) {
        @include base2-medium;
        color: $n3;
        transition: color .4s; }
      &:nth-child(3) {
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 4px 8px;
        @include caption1-medium;
        color: $blue;
        background: rgba($blue,.15);
        border-radius: 11px;
        opacity: 0;
        transition: opacity .4s; } } }

  &__space {
    position: relative;
    @include d {
      display: none; }
    a {
      position: relative;
      display: block;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
      &:hover {
        img {
          transform: scale(1.02); } }
      &:after {
        content: "";
        position: absolute;
        inset: 0;
        border: 1px solid $n7;
        border-radius: 16px; } }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 18px;
      object-position: 50% 0;
      object-fit: cover;
      transition: transform .4s; } }

  &__formats {
    grid-area: 2 / 2 / 3 / 3;
    padding: 23px;
    border: 1px solid $n7;
    border-radius: 16px;
    @include d {
      display: none; } }

  &__title {
    margin-bottom: 16px;
    @include base2-medium;
    color: $n3; }

  &__apps {
    display: flex;
    gap: 16px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      background: $n6;
      border-radius: 50%;
      box-shadow: 0 0 0 2px $n10;
      transition: box-shadow .4s;
      &:hover {
        box-shadow: 0 0 0 2px $blue; } } } }
