@font-face {
  font-family: "CircularXX";
  src: url("/fonts/redesign/CircularXX-Regular.woff2") format("woff2"), url("/fonts/redesign/CircularXX-Regular.woff") format("woff");
  font-weight: 400; }

@font-face {
  font-family: "CircularXX";
  src: url("/fonts/redesign/CircularXX-Book.woff2") format("woff2"), url("/fonts/redesign/CircularXX-Book.woff") format("woff");
  font-weight: 450; }

@font-face {
  font-family: "CircularXX";
  src: url("/fonts/redesign/CircularXX-Medium.woff2") format("woff2"), url("/fonts/redesign/CircularXX-Medium.woff") format("woff");
  font-weight: 500; }
