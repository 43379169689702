.pass {
  position: relative;
  margin-bottom: 4px;

  &__list {
    display: flex;
    @include t {
      margin: 0 -4px;
      padding: 0 2px;
      overflow: auto; } }

  &__item {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 calc(33.33% - 4px);
    max-width: 440px;
    margin: 0 2px;
    padding: 47px;
    background: $new-bg;
    border: 1px solid transparent;
    border-radius: 16px;
    transition: background .7s;
    user-select: none;
    cursor: pointer;
    @include d {
      padding: 32px; }
    @include t {
      flex: 1 0 327px;
      padding: 32px 24px; }
    &.active,
    &.disabled {
      cursor: default; } }
  &__item.active &__btn,
  &__item.disabled &__btn {
    background: $n10;
    border-color: #1C1C1C;
    color: $n4; }
  &__item.active &__btn {
    color: $n1;
    svg {
      fill: $n1; } }

  &__item--first,
  &__item--third {
    &:hover {
      background: #1A1A1C; } }

  &__item--first &__type {
    color: $n4; }

  // &__item--second
  //   background: linear-gradient($new-bg, $new-bg) padding-box, linear-gradient(-135deg, rgba($blue, .75), transparent 45%) border-box
  //   border-radius: 16px
  &__item--second &__type {
    color: $blue; }

  &__item--third &__type {
    color: $green; }

  // &__badge
  //   position: absolute
  //   top: 5px
  //   right: 5px
  //   padding: 4px 8px 6px
  //   background: rgba($blue, .5)
  //   border-radius: 4px 10px 4px 4px
  //   font-size: 12px
  //   font-weight: 450
  //   line-height: 18px
  //   color: #AAE0FF
  //   letter-spacing: 0

  &__type {
    margin-bottom: 8px;
    @include h4; }

  &__price {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
    @include h1;
    color: $n2;
    span {
      margin: 6px 0 0 8px;
      font-size: 20px;
      color: $n4;
      font-weight: 400;
      line-height: 1.4;
      text-decoration: line-through; } }

  &__labels {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 38px 0;
    @include d {
      margin-bottom: 22px; } }

  &__label {
    margin: 0 10px 10px 0;
    padding: 4px 8px 6px;
    background: $n10;
    border-radius: 8px;
    @include link-book;
    color: $n3;
    &:nth-child(2) {
      color: $blue;
      @include d {
        display: none; } } }

  &__details {
    margin: 0 -8px 24px 0;
    @include d {
      margin-bottom: 8px; }
    li {
      position: relative;
      margin-bottom: 24px;
      padding-left: 36px;
      @include sub-headline;
      color: $n3;
      &:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 24px;
        height: 24px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill-rule='evenodd' d='M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm5.207 8.707a1 1 0 0 0-1.414-1.414L10.5 13.586l-2.293-2.293a1 1 0 0 0-1.414 1.414l3 3a1 1 0 0 0 1.414 0l6-6z' fill='%2300a656'/%3E%3C/svg%3E"); } } }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 64px;
    margin: auto auto 0;
    background: $blue;
    border: 2px solid $blue;
    border-radius: 32px;
    @include base1-bold;
    color: $n1;
    transition: background .2s, border-color .2s, color .2s;
    &:hover {
      background: $n1;
      border-color: $n1;
      color: $n8; }
    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px; } }

  &__starter {
    position: fixed;
    right: calc(50% + 24px);
    bottom: 20px;
    z-index: 999;
    cursor: pointer; }

  &__pro {
    position: fixed;
    left: calc(50% + 24px);
    bottom: 20px;
    z-index: 999;
    cursor: pointer; } }
