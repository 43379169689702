.filters {
  position: relative;
  z-index: 4;
  @include d {
    display: flex;
    margin: 0 -32px;
    overflow: auto;
    @include hide-scrollbar; }
  @include t {
    margin: 0 -24px; }

  &__container {
    display: flex;
    justify-content: flex-end;
    @include d {
      display: block; } }

  &__categories,
  tag-filter {
    flex: 1 1 auto;
    height: 44px;
    overflow: hidden;
    @include d {
      overflow: visible; } }

  &__btns {
    display: flex;
    flex-wrap: wrap;
    margin: -8px;
    @include d {
      margin: 0;
      flex-wrap: nowrap;
      &:before,
      &:after {
        content: "";
        flex: 0 0 24px;
        min-width: 24px; } }
    @include t {
      &:before,
      &:after {
        flex: 0 0 16px;
        min-width: 16px; } } }

  &__btns &__btn {
    margin: 8px;
    @include d {
      margin: 0 8px; } }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    height: 44px;
    padding: 0 14px;
    background: none;
    border: 2px solid $n6;
    border-radius: 22px;
    @include base1-bold;
    color: $n3;
    transition: border-color .2s, color .2s;
    &:hover {
      border-color: $n1; }
    &.active {
      border-color: $blue;
      color: $n1; } }

  &__select {
    position: relative;
    margin-left: 16px;
    @include d {
      display: none; }
    &:hover {
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 10px; } } }
  &__select:hover &__head {
    border-color: $n1; }
  &__select:hover &__dropdown {
    display: flex;
    flex-wrap: wrap; }

  &__head {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 4px;
    background: none;
    border: 2px solid $n6;
    border-radius: 22px;
    transition: border-color .2s;
    cursor: pointer; }

  &__label {
    padding: 0 10px;
    @include base1-bold;
    color: $n3; }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: $n6;
    border-radius: 50%;
    svg {
      width: 24px;
      height: 24px;
      fill: $n3; }
    img {
      width: 16px;
      height: 16px; } }

  &__dropdown {
    display: none;
    position: absolute;
    top: 54px;
    right: 0;
    width: 212px;
    padding: 16px;
    background: $n7;
    border-radius: 24px; }
  &__dropdown.isWide {
    width: 392px; }
  &__dropdown.isWide &__btn {
    width: calc(50% - 16px); }
  &__dropdown.isWider {
    width: 572px; }
  &__dropdown.isWider &__btn {
    width: calc(33.33% - 16px); }
  &__dropdown.isWiderest {
    width: 752px; }
  &__dropdown.isWiderest &__btn {
    width: calc(25% - 16px); }
  &__dropdown &__btn {
    display: flex;
    justify-content: flex-start;
    width: calc(100% - 16px);
    margin: 8px;
    @include link;
    img {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      object-fit: cover; } }

  featured-filter {
    flex-grow: 1;
    margin: 8px; }

  &__toggle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 0 16px;
    background: none;
    border: none;
    @include link;
    color: $n3;
    transition: color .2s;
    &:before {
      content: "";
      position: absolute;
      top: 4px;
      right: 20px;
      width: 14px;
      height: 14px;
      background: $n5;
      border-radius: 50%;
      transition: background .2s, transform .2s; }
    &:after {
      content: "";
      width: 38px;
      height: 22px;
      border: 2px solid $n6;
      border-radius: 11px;
      transition: border-color .2s; }
    &:hover {
      &:after {
        border-color: $n1; } }
    &.active {
      color: $n1;
      &:before {
        background: $n1;
        transform: translateX(16px); }
      &:after {
        border-color: $blue; } } }

  &--home {
    display: flex; }
  &--home &__btns {
    margin-left: auto;
    margin-right: auto;
    @include m {
      margin: 0; } }
  &--home &__btn {
    min-width: 130px; }

  &--tag {
    display: flex;
    justify-content: flex-end; } }
