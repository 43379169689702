.aap {
  padding: 88px 0 128px;
  @include t {
    padding: 70px 0 80px; }


  &__container {
    @include t {
      padding: 0 4px; } }

  &__head {
    position: relative;
    display: flex;
    margin: 0 -2px 4px; }

  &__cell {
    margin: 0 2px;
    background: $new-bg;
    border-radius: 16px;
    &:nth-child(1) {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 calc(33.33% - 4px);
      padding: 48px;
      @include d {
        padding: 32px; }
      @include t {
        justify-content: flex-end;
        flex: 0 0 calc(100% - 4px);
        height: 280px;
        padding: 24px;
        background: none; }
      @include m {
        height: auto;
        padding: 240px 32px 24px; } }
    &:nth-child(2) {
      flex: 0 0 calc(66.66% - 4px);
      height: 360px;
      @include t {
        position: absolute;
        top: 0;
        left: 240px;
        right: 0;
        height: 100%;
        aspect-ratio: auto;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: -240px;
          bottom: 0;
          width: 240px;
          background: $new-bg;
          border-radius: 16px 0 0 16px; }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(to right, $new-bg, transparent);
          border-radius: 0 16px 16px 0; } }
      @include m {
        left: 0;
        &:before {
          top: auto;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 100px;
          border-radius: 0 0 16px 16px; }
        &:after {
          bottom: 100px;
          background: linear-gradient(transparent, $new-bg);
          border-radius: 16px 16px 0 0; } }
      img {
          width: 100%;
          height: 100%;
          border-radius: 16px;
          object-fit: cover; } } }

  &__title {
    margin-bottom: 12px;
    @include h2;
    letter-spacing: -.05em;
    @include m {
      @include h3; } }

  &__text {
    @include sub-headline;
    color: $n3;
    @include t {
      max-width: 360px; } }

  &__row {
    display: flex;
    margin: 0 -2px;
    @include d {
      flex-direction: column-reverse;
      margin: 0; } }

  &__col {
    flex: 0 0 calc(50% - 4px);
    margin: 0 2px;
    @include d {
      flex: auto;
      margin: 0; }
    &:first-child {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 72px 48px 48px;
      background: $new-bg;
      border-radius: 16px;
      @include d {
        padding: 72px 32px 32px; }
      @include t {
        padding: 72px 24px 24px; }
      @include m {
        padding-top: 48px; } } }

  &__label {
    margin-bottom: 16px;
    padding: 4px 8px 6px;
    background: $n10;
    border-radius: 8px;
    @include link-book;
    color: $blue; }

  &__subtitle {
    margin-bottom: 48px;
    @include h3;
    color: $n2; }

  &__faq {
    width: 100%;
    margin-bottom: 80px;
    border-top: 1px solid $n7; }

  &__accordion {
    border-bottom: 1px solid $n7;
    transition: border-color .2s;
    &:hover {
      border-color: $blue; } }

  &__question {
    position: relative;
    padding: 32px 86px 32px 0;
    font-size: 19px;
    font-weight: 500;
    color: $n3;
    line-height: 28px;
    transition: color .2s;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 44px;
      right: 4px;
      width: 16px;
      height: 2px;
      background: $n6;
      border-radius: 1px;
      transition: background .2s, transform .2s; }
    &:after {
      transform: rotate(90deg); }
    &:hover {
      color: $blue;
      &:before,
      &:after {
        background: $blue; } }
    &.active {
      &:after {
        transform: rotate(0deg); } } }

  &__answer {
    display: none;
    padding-bottom: 32px;
    @include sub-headline;
    color: $n3; }

  &__hint {
    display: flex;
    align-items: flex-start;
    margin-top: auto;
    @include body1;
    color: $n3;
    svg {
      flex-shrink: 0;
      margin: 1px 8px 0 0; }
    a {
      border-bottom: 1px solid $n2;
      color: $n2;
      transition: border-color .2s, color .2s;
      &:hover {
        border-color: $blue;
        color: $blue; } } }

  &__testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 128px 48px;
    background: $new-bg;
    border-radius: 16px;
    @include m {
      display: none; } } }

.aap-image {
  position: absolute;
  z-index: -1;
  transform: translate3d(0,0,0);
  mix-blend-mode: lighten;
  pointer-events: none;
  @include t {
    display: none; }
  &--top {
    top: 0;
    left: 0;
    border-radius: 16px 16px 0 0; }
  &--top-right {
    top: 0;
    right: 0;
    width: percentage(140/440);
    border-radius: 0 16px 0 0; }
  &--top-right-large {
    top: 0;
    right: 0;
    width: percentage(611/884);
    border-radius: 0 16px 0 0; }
  &--top-left-medium {
    top: 0;
    left: 0;
    width: percentage(594/662);
    border-radius: 16px 0 0 0;
    @include d {
      display: none; } }
  &--top-left-large {
    top: 0;
    left: 0;
    width: percentage(611/884);
    border-radius: 16px 0 0 0; }
  &--left {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 16px 0 0 16px; }
  &--right {
    top: 0;
    right: 0;
    height: 100%;
    border-radius: 0 16px 16px 0; }
  &--right-bottom-large {
    right: 0;
    bottom: 0;
    width: percentage(611/884);
    border-radius: 0 0 16px; }
  &--bottom {
    left: 0;
    bottom: 0;
    border-radius: 0 0 16px 16px; }
  &--bottom-left {
    left: 0;
    bottom: 0;
    width: percentage(140/440);
    border-radius: 0 0 0 16px; }
  &--bottom-left-xxl {
    left: 0;
    bottom: 0;
    width: percentage(611/1328);
    border-radius: 0 0 0 16px; }
  &--bottom-left-large {
    left: 0;
    bottom: 0;
    width: percentage(594/884);
    border-radius: 0 0 0 16px; } }
