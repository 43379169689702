.featured {
  position: relative;
  z-index: 2;
  &:not(:last-child) {
    padding-bottom: 64px;
    @include m {
      padding-bottom: 48px; } }
  &:first-child &__anchor {
    @include t {
      top: -164px; } }

  &__anchor {
    position: absolute;
    top: -118px;
    left: 0;
    width: 100%;
    height: 1px;
    pointer-events: none;
    @include t {
      top: -180px; } }

  &__head {
    margin-bottom: 40px; }

  &__subtitle {
    margin-bottom: 16px;
    @include h5; }

  &__group {
    display: flex;
    align-items: flex-start;
    @include m {
      display: block; } }

  &__content {
    max-width: 694px;
    margin-right: 40px;
    @include body1;
    color: $n3;
    @include d {
      max-width: 480px; }
    @include m {
      margin: 0 0 24px; } }

  &__btn {
    flex-shrink: 0;
    margin-left: auto; }

  &__catalog {
    margin-bottom: -24px; } }
