.formats {
  display: flex;
  padding-left: 8px;
  li {
    position: relative;
    display: none;
    width: 32px;
    height: 32px;
    margin-left: -8px;
    background: $n6;
    border-radius: 50%;
    box-shadow: 0 0 0 2px $n8;
    &:hover {
      &:before,
      &:after {
        opacity: 1; } }
    &:before,
    &:after {
      position: absolute;
      opacity: 0;
      transition: opacity .2s;
      pointer-events: none; }
    &:before {
      content: attr(data-title);
      left: 50%;
      bottom: calc(100% + 16px);
      padding: 4px 8px;
      background: $n6;
      border-radius: 6px;
      @include link-bold;
      color: $n2;
      white-space: nowrap;
      transform: translateX(-50%); }
    &:after {
      content: "";
      left: calc(50% - 6px);
      bottom: calc(100% + 10px);
      width: 12px;
      height: 8px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8'%3E%3Cpath fill='%232c2c2c' d='M10.4 0a1 1 0 0 1 .776 1.631L6.777 7.045a1 1 0 0 1-1.552 0L.826 1.631A1 1 0 0 1 1.602 0H10.4z'/%3E%3C/svg%3E"); } } }

.swift-compatible .app-swift,
.android-compatible .app-android,
.xcode-compatible .app-xcode,
.html-compatible .app-html,
.sketch-compatible .app-sketch,
.sketch-compatible .app-lunacy,
.maya-compatible .app-maya,
.blender-compatible .app-blender,
._3dstudiomax-compatible .app-3dsmax,
.cinema4d-compatible .app-cinema4d,
.tumblr-compatible .app-tumblr,
.indesign-compatible .app-indesign,
.wordpress-compatible .app-wordpress,
.photoshop-compatible .app-photoshop,
.wordpress-compatible .app-wordpress,
.illustrator-compatible .app-illustrator,
.omnigraffle-compatible .app-omnigraffle,
.aftereffects-compatible .app-aftereffects,
.powerpoint-compatible .app-powerpoint,
.keynote-compatible .app-keynote,
.xd-compatible .app-xd,
.framer-compatible .app-framer,
.adobeanimate-compatible .app-animate,
.figma-compatible .app-figma,
.procreate-compatible .app-procreate,
.lunacy-compatible .app-lunacy,
.notion-compatible .app-notion,
.react-compatible .app-react,
.bootstrap-compatible .app-bootstrap,
.spline-compatible .app-spline {
  display: flex;
  align-items: center;
  justify-content: center; }
