.hire {
  padding: 40px 40px 32px;
  background: $n7;
  border-radius: 24px;
  @include shadow;
  @include m {
    padding: 0;
    box-shadow: none; }

  &__step {
    min-height: 474px; }

  &__title {
    margin-bottom: 26px;
    @include h4; }

  &__content {
    min-height: 372px;
    margin-top: -10px;
    padding-top: 10px;
    @include body1;
    color: $n3;
    p {
      margin-bottom: 16px; } }

  .field {
    margin-bottom: 26px;
    &__textarea {
      height: 262px; } }

  .btn,
  .loader {
    margin-top: -2px; } }
