.catalog {
  position: relative;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px -24px;

  product-card {
    flex: 0 0 calc(25% - 24px);
    margin: 0 12px 24px;
    overflow: hidden;
    @include d {
      flex: 0 0 calc(33.33% - 24px); }
    @include t {
      flex: 0 0 calc(50% - 24px); }
    @include m {
      flex: 0 0 calc(100% - 24px); } }

  &--three {
    product-card {
      flex: 0 0 calc(33.33% - 24px);
      @include d {
        flex: 0 0 calc(50% - 24px); }
      @include m {
        flex: 0 0 calc(100% - 24px); } } } }
