.download {

  &__preview {
    aspect-ratio: 1.4830;
    background: $n9;
    border-radius: 16px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: cover; } }

  &__buttons {
    li {
      margin-top: 16px; } }

  .btn {
    padding: 0 16px;
    font-weight: 450;
    overflow: hidden;
    span {
      &:nth-child(1) {
        flex: 0 1 auto;
        margin-right: 4px;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      &:nth-child(2) {
        margin-right: 16px; } }
    svg {
      flex-shrink: 0;
      margin-left: auto; } } }
