.auth {
  display: flex;
  width: 840px;
  margin: 0 auto;
  background: $n7;
  border-radius: 24px;
  @include shadow;
  @include t {
    display: block;
    width: 420px; }
  @include m {
    width: 100%;
    margin: 0;
    box-shadow: none; }

  &__background,
  &__container {
    flex: 0 0 420px; }

  &__background {
    position: relative;
    @include t {
      display: none; }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 24px 0 0 24px;
      object-fit: cover; } }

  &__container {
    padding: 40px 40px 32px;
    @include m {
      padding: 0; } }

  &__title {
    margin-bottom: 26px;
    @include h4; }

  &__content {
    margin: -10px 0 34px;
    @include body1;
    color: $n3;
    p {
      margin-bottom: 16px; }
    &--registered {
      min-height: 278px;
      margin-bottom: 0; } }

  &__hint {
    margin-top: 24px;
    @include caption1-book;
    color: rgba($n1,.75);
    text-align: center;
    @include m {
      text-align: left; }
    button,
    a {
      @include button-s;
      color: $n1;
      &:hover {
        text-decoration: underline; } } }

  .field {
    margin-bottom: 26px; }

  .btn,
  .loader {
    margin-top: -2px; }

  &--author {
    min-height: 534px; } }
