.renew {
  position: relative;
  display: flex;
  align-items: center;
  color: rgba($n1,.75);
  @include m {
    margin-right: 16px; }

  & + .renew {
    margin-left: 32px;
    @include m {
      margin-left: 0; } }

  &:hover &__counter {
    &--upper {
      opacity: 0; }
    &--remaining {
      opacity: 1; } }

  &__counter {
    width: 172px;
    transition: opacity .4s;
    @include d {
      width: 120px; }
    &--remaining {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0; } }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    span {
      &:first-child {
        @include caption1-book; }
      &:last-child {
        font-size: 13px;
        font-weight: 450;
        line-height: 16px;
        color: $n1; } } }

  &__msg {
    display: flex;
    align-items: center;
    margin-right: 8px;
    @include link-book;
    white-space: nowrap;
    &:after {
      content: "";
      width: 6px;
      height: 8px;
      margin-left: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.5C0 0.675955 0.940764 0.205573 1.6 0.7L4.93333 3.2C5.46667 3.6 5.46667 4.4 4.93333 4.8L1.6 7.3C0.940764 7.79443 0 7.32405 0 6.5V1.5Z' fill='%234D4D4D'/%3E%3C/svg%3E%0A"); } }

  &__btn {
    display: flex;
    align-items: center;
    height: 32px;
    padding: 0 18px;
    background: $blue;
    border-radius: 16px;
    @include button-s;
    color: $n1;
    transition: background .2s, color .2s;
    &:hover {
      background: $n1;
      color: $n7; } }

  .progress-bar {
    height: 6px;
    background: $n6;
    border-radius: 3px;
    &.red {
      .fill-bar {
        background: $red; } }

    .fill-bar {
      max-width: 100%;
      height: 6px;
      background: $green;
      border-radius: 3px; } } }
