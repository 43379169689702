.page {
  position: relative;
  padding: 180px 0 128px;
  @include m {
    padding-top: 120px; }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 680px;
    &:after {
      content: "";
      position: absolute;
      inset: 0;
      background: linear-gradient(transparent 66%, $n10); }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: .12; } }

  &__container {
    position: relative;
    z-index: 2; }

  &__hero {
    max-width: 748px;
    margin: 0 auto 128px;
    text-align: center;
    @include t {
      max-width: 608px;
      margin-bottom: 80px; }
    @include m {
      max-width: 100%;
      margin-bottom: 48px;
      text-align: left; } }

  &__title {
    margin-bottom: 16px;
    @include h2;
    letter-spacing: -1px; }

  &__description {
    @include sub-headline;
    color: $n3; }

  &__link {
    display: inline-flex;
    align-items: center;
    margin-top: 16px;
    @include link;
    color: $blue;
    svg {
      margin-left: 4px; } }

  &__featured {
    display: inline-flex;
    align-items: center;
    &:after {
      content: "";
      margin-left: 6px;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill-rule='evenodd' d='M2.031 12c0-.134-.027-.268-.079-.393L1.231 9.86C1.079 9.492 1 9.097 1 8.699s.078-.793.231-1.161.376-.703.658-.984.616-.505.984-.657l1.744-.722c.253-.104.454-.305.559-.557l.723-1.744c.308-.743.898-1.333 1.641-1.641s1.578-.308 2.321 0l1.743.722c.253.104.537.104.79-.001l.002-.001 1.745-.72c.743-.307 1.578-.307 2.321 0s1.333.898 1.641 1.641l.705 1.703.018.041a1.03 1.03 0 0 0 .558.559l1.745.723c.743.308 1.334.898 1.641 1.641s.308 1.578 0 2.321l-.722 1.744c-.053.127-.079.262-.079.396a1.03 1.03 0 0 0 .079.395l.722 1.744c.308.743.308 1.578 0 2.321s-.898 1.334-1.641 1.641l-1.745.723c-.253.105-.453.306-.558.559l-.018.041-.705 1.703a3.03 3.03 0 0 1-1.641 1.641c-.743.308-1.578.308-2.321 0l-1.745-.72-.002-.001c-.253-.105-.537-.105-.79-.001l-1.743.722a3.03 3.03 0 0 1-2.321 0c-.743-.308-1.333-.898-1.641-1.641l-.723-1.744c-.105-.252-.307-.453-.559-.557l-1.744-.722c-.368-.152-.702-.376-.984-.657s-.505-.616-.658-.984S1 15.7 1 15.301s.079-.793.231-1.161l.721-1.747c.052-.126.079-.26.079-.393zm14.176-1.793a1 1 0 0 0-1.414-1.414L11 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4.5-4.5z' fill='%2300a656'/%3E%3C/svg%3E"); } }

  &__trigger {
    height: 1px;
    margin-bottom: -1px; }

  &__filters {
    margin-bottom: 24px;
    padding-bottom: 24px;
    transition: background .2s;
    @include m {
      margin-bottom: 16px;
      padding-bottom: 16px; }
    &--sticky {
      position: sticky;
      top: 88px;
      z-index: 40;
      @include d {
        top: 72px; }
      &.scrolled {
        background: rgba($n9,.98); } } }

  &__catalog {
    margin-bottom: 24px; }

  &__foot {
    text-align: center; }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    .btn {
      &.disabled {
        opacity: 0;
        pointer-events: none; } } }

  &__counter {
    margin: 0 16px;
    @include base1-bold;
    color: $n3; }

  &--passes &__background {
    height: 878px;
    @include m {
      height: 520px; } }

  &--additional &__hero {
    margin-bottom: 80px;
    @include m {
      margin-bottom: 48px; } }

  &--auth {
    display: flex;
    min-height: 100vh;
    @include m {
      display: block;
      padding: 110px 0 40px;
      background: $n7; } }
  &--auth &__background {
    height: 100%;
    @include m {
      display: none; } }
  &--auth &__container {
    margin: auto;
    @include m {
      margin: 0; } } }


