body {
  background: $n10;
  font-family: "CircularXX", sans-serif;
  font-size: 14px;
  color: $n1;
  letter-spacing: -.02em;
  &.cart {
    &:before {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      z-index: 48;
      width: 100%;
      height: 100%;
      background: rgba($n9,.98); }
    .intercom-lightweight-app {
      @include m {
        display: none; } } }
  &.page-product {
    &.loaded {
      .header__group--right {
        display: flex; } }
    .header__group--right {
      display: none; } } }

button,
input,
textarea,
select {
  font-family: "CircularXX", sans-serif; }

button {
  background: none; }

a {
  text-decoration: none;
  cursor: pointer; }

img {
  display: block;
  max-width: 100%; }

strong {
  font-weight: 500; }

.grecaptcha-badge {
  visibility: hidden; }

[ui8-go-tab] {
  position: absolute;
  opacity: 0;
  pointer-events: none; }

.body-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

.body-content {
  flex-grow: 1; }

.ui-helper-hidden-accessible {
  display: none; }
