.pheader {
  position: relative;
  background: $n9;

  &__background {
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: .05;
    filter: grayscale(1); }

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 332px;
    padding-top: 88px;
    padding-bottom: 88px;
    @include m {
      padding-bottom: 140px; } }

  &__title {
    max-width: 860px;
    margin-bottom: 8px;
    @include h3;
    letter-spacing: -1px;
    &.isFeatured {
      span {
        padding-right: 32px; }
      &:after {
        content: "";
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-left: -24px;
        vertical-align: top;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill-rule='evenodd' d='M2.031 12c0-.134-.027-.268-.079-.393L1.231 9.86C1.079 9.492 1 9.097 1 8.699s.078-.793.231-1.161.376-.703.658-.984.616-.505.984-.657l1.744-.722c.253-.104.454-.305.559-.557l.723-1.744c.308-.743.898-1.333 1.641-1.641s1.578-.308 2.321 0l1.743.722c.253.104.537.104.79-.001l.002-.001 1.745-.72c.743-.307 1.578-.307 2.321 0s1.333.898 1.641 1.641l.705 1.703.018.041a1.03 1.03 0 0 0 .558.559l1.745.723c.743.308 1.334.898 1.641 1.641s.308 1.578 0 2.321l-.722 1.744c-.053.127-.079.262-.079.396a1.03 1.03 0 0 0 .079.395l.722 1.744c.308.743.308 1.578 0 2.321s-.898 1.334-1.641 1.641l-1.745.723c-.253.105-.453.306-.558.559l-.018.041-.705 1.703a3.03 3.03 0 0 1-1.641 1.641c-.743.308-1.578.308-2.321 0l-1.745-.72-.002-.001c-.253-.105-.537-.105-.79-.001l-1.743.722a3.03 3.03 0 0 1-2.321 0c-.743-.308-1.333-.898-1.641-1.641l-.723-1.744c-.105-.252-.307-.453-.559-.557l-1.744-.722c-.368-.152-.702-.376-.984-.657s-.505-.616-.658-.984S1 15.7 1 15.301s.079-.793.231-1.161l.721-1.747c.052-.126.079-.26.079-.393zm14.176-1.793a1 1 0 0 0-1.414-1.414L11 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4.5-4.5z' fill='%2300a656'/%3E%3C/svg%3E"); } } }

  &__description {
    @include sub-headline;
    color: $n3; } }
