[ui8-avatar] {
  display: block;
  background-color: $n5;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='%23FFF'%3E%3Cpath d='M12.5 15c3.683 0 7.138 1.568 9.728 4.314a1 1 0 0 1-1.455 1.372C18.552 18.331 15.618 17 12.5 17s-6.052 1.331-8.272 3.686a1 1 0 0 1-1.455-1.372C5.362 16.568 8.817 15 12.5 15zm0-13a5.5 5.5 0 1 0 0 11 5.5 5.5 0 1 0 0-11zm0 2a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center;
  border-radius: 50%; }


