.browse {
  margin-bottom: -24px;
  column-count: 3;
  column-gap: 40px;
  li {
    margin-bottom: 24px; }
  a {
    display: flex;
    align-items: center;
    @include base2-medium;
    color: $n3;
    transition: color .2s;
    &:hover {
      color: $n1;
      svg {
        fill: $n1; } } }
  svg {
    width: 16px;
    height: 16px;
    margin-right: 16px;
    fill: $n4;
    transition: fill .2s; } }
