.discussion {
  max-width: 1068px;
  margin: 0 auto;
  background: $n8;
  border-radius: 24px;
  @include t {
    margin: 0 -24px;
    border-radius: 0; }

  &__container {
    padding: 48px 64px;
    @include t {
      padding: 32px; }
    @include m {
      padding: 24px; } }

  &__head {
    display: flex;
    align-items: center;
    min-height: 44px; }

  &__title {
    @include h4; }

  &__sorting {
    display: flex;
    margin-left: auto;
    @include t {
      display: none; }
    .btn {
      margin-left: 16px;
      padding: 0 14px;
      &.active {
        border-color: $blue;
        color: $n1; } } }

  &__loader {
    margin-top: 32px;
    @include t {
      margin-top: 40px; } }

  &__list {
    margin: 32px -16px 0;
    @include t {
      margin-top: 40px; } }

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 16px;
    border-radius: 24px;
    transition: background .2s, opacity .2s;
    @include d {
      display: block; }
    &:not(:last-child) {
      margin-bottom: 10px; }
    &.isAdmin,
    &.isEditing {
      background: $n9; }
    &.isDisabled {
      opacity: .25;
      pointer-events: none; } }

  &__avatar {
    flex-shrink: 0;
    width: 58px;
    height: 58px;
    margin-right: 24px;
    border-radius: 50%;
    @include d {
      display: none; }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      &:first-child {
        pointer-events: none; }
      &:last-child {
        position: absolute;
        top: 0;
        left: 0; } } }

  &__layout {
    display: flex;
    align-items: flex-start;
    flex: 0 0 calc(100% - 82px);
    @include d {
      display: block; } }

  &__inner {
    flex: 1 1 auto;
    transition: opacity .2s;
    &.isDeleting {
      opacity: .25; } }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: 6px; }

  &__name {
    margin-right: 8px;
    @include h6;
    color: $n1; }

  &__role {
    margin-left: 8px;
    padding: 2px 6px;
    border: 2px solid $green;
    border-radius: 8px;
    @include link-bold;
    color: $green; }

  &__content {
    max-width: 660px;
    @include body1;
    color: $n3;
    white-space: pre-wrap;
    a {
      color: $n1; } }

  &__date {
    margin-top: 6px;
    @include base1;
    color: $n4; }

  &__actions {
    display: flex;
    align-items: center;
    margin-left: 32px;
    @include d {
      margin: 12px 0 0; }
    .btn {
      &:not(:first-child) {
        margin-left: 16px; } } }

  &__caption {
    flex-shrink: 0;
    @include body1;
    color: $n3; }

  &__pagination {
    margin-top: 8px;
    text-align: center;
    .btn {
      padding: 0 14px; } }

  &__adding {
    position: relative;
    .loader,
    .btn {
      position: absolute;
      top: 32px;
      right: 64px;
      @include t {
        right: 32px; }
      @include m {
        right: 24px;
        padding: 0;
        background: none;
        border: none;
        font-size: 0; }
      svg {
        display: none;
        @include m {
          display: block;
          width: 44px;
          height: 44px;
          margin: 0;
          fill: $n5; } } } }

  &__field {
    position: relative; }
  &__field &__text {
    opacity: 0; }

  &__field--editing &__text,
  &__field--editing &__textarea {
    padding: 0 0 30px;
    border-radius: 0;
    @include t {
      padding: 0 0 30px; }
    @include m {
      padding: 0 0 30px; } }
  &__field--editing &__text {
    min-height: 58px; }

  &__text,
  &__textarea {
    padding: 40px 212px 40px 64px;
    background: $n9;
    border-radius: 0 0 24px 24px;
    @include body1;
    @include t {
      border-radius: 0;
      padding-left: 32px;
      padding-right: 170px; }
    @include m {
      padding-left: 24px;
      padding-right: 92px; } }

  &__text {
    min-height: 108px;
    white-space: pre-wrap;
    color: $n3; }

  &__textarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    resize: none;
    color: $n3;
    caret-color: $blue;
    letter-spacing: -.02em; }

  &__error {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 24px 64px;
    @include base2-medium;
    color: $pink; } }
