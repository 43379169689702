.libs {
  position: relative;
  @include d {
    margin-bottom: 4px; }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    @include d {
      grid-template-columns: repeat(5, 1fr); }
    @include t {
      grid-template-columns: repeat(2, 1fr); } }

  &__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    background: $new-bg;
    border-radius: 16px; }
  &__link:hover &__icon {
    filter: grayscale(0); }

  &__icon {
    width: 64px;
    height: 64px;
    margin-bottom: 10px;
    filter: grayscale(.9);
    transition: filter .4s;
    @include d {
      width: 52px;
      height: 52px; }
    svg {
      width: 64px;
      height: 64px;
      @include d {
        width: 52px;
        height: 52px; } } }

  &__counter {
    margin-bottom: 10px;
    @include h3;
    color: $n1;
    @include d {
      margin: 0; } }

  &__title {
    @include sub-headline;
    color: $n3;
    @include d {
      @include body1; } }

  &__list--overlay {
    position: absolute;
    inset: 0;
    z-index: 2;
    opacity: var(--opacity, 0);
    mask: radial-gradient(480px 480px at var(--x) var(--y), black 1%, transparent 40%);
    will-change: mask;
    user-select: none;
    pointer-events: none;
    @include touch {
      display: none; }
    @include t {
      display: none; }
    li {
      border: 1px solid $blue;
      border-radius: 16px;
      aspect-ratio: 1; } } }
