.testimonials {
  width: 100%;
  margin-bottom: -16px;
  column-count: 4;
  column-gap: 16px;
  @include d {
    column-count: 3; }
  @include t {
    column-count: 2; }

  li {
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    padding: 24px;
    background: rgba($n7, .5);
    border-radius: 16px;
    vertical-align: top; }

  blockquote {
    margin-bottom: 24px;
    @include sub-headline;
    color: $n3; }

  img {
    position: absolute;
    left: 24px;
    bottom: 24px;
    width: 40px;
    height: 40px;
    border-radius: 50%; }

  h4 {
    display: inline-flex;
    align-items: center;
    min-height: 40px;
    padding-left: 52px;
    @include body2; } }
