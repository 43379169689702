.companies {
  position: relative;
  z-index: 2;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 90px 40px;
  background-color: $new-bg;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  border-radius: 16px;
  text-align: center;
  overflow: hidden;
  @include m {
    padding: 54px 0; }

  &__title {
    margin-bottom: 16px;
    @include h3;
    color: $n2; }

  &__text {
    margin-bottom: 48px;
    @include sub-headline;
    color: $n3; }

  &__carousel {
    position: relative;
    height: 48px;
    margin: 0 -40px;
    overflow: hidden;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      z-index: 2;
      width: 200px;
      height: 48px;
      background: linear-gradient(to right, $new-bg, transparent); }
    &:before {
      left: 0; }
    &:after {
      right: 0;
      transform: rotate(180deg); } }

  &__list {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    animation: companies1 80s linear infinite;
    picture {
      margin-right: 64px; }
    img {
      max-width: unset; }
    &:last-child {
      animation: companies2 80s linear infinite; } } }

@keyframes companies1 {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100%); } }

@keyframes companies2 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0%); } }
