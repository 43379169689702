.pnav {
  position: sticky;
  top: 88px;
  z-index: 10;
  margin: -68px 0 24px;
  padding-bottom: 24px;
  @include d {
    top: 72px;
    margin-bottom: 8px; }
  @include m {
    position: relative;
    top: auto;
    margin-top: -124px; }
  &.scrolled {
    background: rgba($n9,.98);
    @include m {
      background: none; } }
  &.scrolled &__slide {
    &:first-child {
      opacity: 0;
      pointer-events: none;
      @include t {
        opacity: 1;
        pointer-events: all; } }
    &:last-child {
      opacity: 1;
      pointer-events: all;
      @include t {
        opacity: 0;
        pointer-events: none; } } }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    @include m {
      flex-direction: column;
      align-items: flex-end;
      height: 100px; } }

  &__slides {
    position: relative;
    min-width: 0;
    height: 44px;
    margin-right: auto;
    @include m {
      height: 32px; } }

  &__slide {
    transition: opacity .2s;
    &:first-child {
      position: absolute;
      top: 0;
      left: 0;
      @include t {
        position: static; } }
    &:last-child {
      display: flex;
      align-items: center;
      height: 44px;
      opacity: 0;
      pointer-events: none;
      @include t {
        display: none; } } }

  &__meta {
    display: flex;
    align-items: center;
    height: 44px;
    @include m {
      height: auto; }
    .formats {
      @include t {
        display: none; } } }

  &__avatar {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    background: $n9;
    border-radius: 50%;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; } }

  &__link {
    flex-shrink: 0;
    @include link-book;
    color: $n3;
    transition: color .2s;
    &:hover {
      color: $n1; } }

  &__arrow {
    flex-shrink: 0;
    width: 6px;
    height: 8px;
    margin: 0 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.5C0 0.675955 0.940764 0.205573 1.6 0.7L4.93333 3.2C5.46667 3.6 5.46667 4.4 4.93333 4.8L1.6 7.3C0.940764 7.79443 0 7.32405 0 6.5V1.5Z' fill='%234D4D4D'/%3E%3C/svg%3E%0A");
    @include t {
      display: none; } }
  &__arrow + &__link {
    @include t {
      display: none; } }

  &__title {
    @include h4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.isFeatured {
      &:after {
        content: "";
        display: inline-block;
        position: relative;
        top: -3px;
        width: 24px;
        height: 24px;
        margin-left: 8px;
        vertical-align: middle;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill-rule='evenodd' d='M2.031 12c0-.134-.027-.268-.079-.393L1.231 9.86C1.079 9.492 1 9.097 1 8.699s.078-.793.231-1.161.376-.703.658-.984.616-.505.984-.657l1.744-.722c.253-.104.454-.305.559-.557l.723-1.744c.308-.743.898-1.333 1.641-1.641s1.578-.308 2.321 0l1.743.722c.253.104.537.104.79-.001l.002-.001 1.745-.72c.743-.307 1.578-.307 2.321 0s1.333.898 1.641 1.641l.705 1.703.018.041a1.03 1.03 0 0 0 .558.559l1.745.723c.743.308 1.334.898 1.641 1.641s.308 1.578 0 2.321l-.722 1.744c-.053.127-.079.262-.079.396a1.03 1.03 0 0 0 .079.395l.722 1.744c.308.743.308 1.578 0 2.321s-.898 1.334-1.641 1.641l-1.745.723c-.253.105-.453.306-.558.559l-.018.041-.705 1.703a3.03 3.03 0 0 1-1.641 1.641c-.743.308-1.578.308-2.321 0l-1.745-.72-.002-.001c-.253-.105-.537-.105-.79-.001l-1.743.722a3.03 3.03 0 0 1-2.321 0c-.743-.308-1.333-.898-1.641-1.641l-.723-1.744c-.105-.252-.307-.453-.559-.557l-1.744-.722c-.368-.152-.702-.376-.984-.657s-.505-.616-.658-.984S1 15.7 1 15.301s.079-.793.231-1.161l.721-1.747c.052-.126.079-.26.079-.393zm14.176-1.793a1 1 0 0 0-1.414-1.414L11 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4.5-4.5z' fill='%2300a656'/%3E%3C/svg%3E"); } } }

  &__group {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    @include m {
      justify-content: space-between;
      width: 100%; } }

  &__cell {
    margin-left: 16px;
    @include m {
      margin: 0; } }

  &__select {
    position: relative;
    @include d {
      display: none; }
    &:hover {
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 10px; } } }
  &__select:hover {
    & > .btn {
      border-color: $n1; } }
  &__select:hover &__dropdown {
    display: flex;
    flex-wrap: wrap; }

  &__dropdown {
    display: none;
    position: absolute;
    top: 54px;
    right: 0;
    width: 212px;
    padding: 16px;
    background: $n7;
    border-radius: 24px;
    .btn {
      justify-content: flex-start;
      width: calc(100% - 16px);
      margin: 8px;
      padding: 0 14px;
      @include link; } } }

