.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  padding: 40px 56px;
  opacity: 0;
  transition: opacity .2s;
  pointer-events: none;
  overflow: auto;
  @include m {
    display: block;
    padding: 0;
    background: $n7;
    padding: 110px 24px 40px; }

  &.open {
    opacity: 1;
    pointer-events: all; }

  &__close {
    position: fixed;
    top: 40px;
    right: 56px;
    z-index: 4;
    width: 44px;
    height: 44px;
    background: $n9;
    border: 2px solid $n6;
    border-radius: 50%;
    font-size: 0;
    transition: border-color .2s;
    @include m {
      top: 24px;
      right: 24px; }
    &:before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23ADB7BE'%3E%3Cpath d='M6.988 5.585l.094.083L12 10.585l4.918-4.917a1 1 0 0 1 1.497 1.32l-.083.094L13.415 12l4.917 4.918a1 1 0 0 1-1.32 1.497l-.094-.083L12 13.415l-4.918 4.917a1 1 0 0 1-1.497-1.32l.083-.094L10.585 12 5.668 7.082a1 1 0 0 1 1.219-1.567l.101.069z'/%3E%3C/svg%3E");
      vertical-align: middle; }
    &:hover {
      border-color: $n1; } }

  &__container {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 1060px;
    margin: auto;
    @include m {
      margin: 0; } }

  &__head {
    max-width: 748px;
    margin: 0 auto 56px;
    text-align: center; }

  &__title {
    margin-bottom: 16px;
    @include h2; }

  &__description {
    @include body1;
    color: $n3; }

  &__video {
    .video-wrapper {
      position: relative;
      overflow: hidden;
      &:before {
        content: "";
        display: block;
        padding-bottom: 56.25%; }
      > * {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
        outline: none; } }
    pre {
      display: none; } }

  &__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($n9,.98);
    @include m {
      display: none; } }

  &--pass {
    padding: 80px 40px;
    @include d {
      padding: 80px 24px; }
    @include m {
      padding: 80px 24px;
      background: $n10; }
    .pass {
      @include d {
        margin: 0 -20px; } }
    .aap-image {
      display: none; } }
  &--pass &__container {
    max-width: 1328px; }
  &--pass &__backdrop {
    background: $n10; }

  &--auth &__container {
    max-width: initial;
    width: auto; }

  &--full-preview {
    padding-top: 0;
    padding-bottom: 0;
    @include d {
      padding: 0; }
    @include m {
      display: none; } }
  &--full-preview &__close {
    @include dmax {
      right: 40px; }
    @include d {
      top: 24px;
      right: 24px; } }

  &--hire &__container {
    max-width: 520px;
    @include m {
      max-width: 100%; } }

  &--download &__container {
    max-width: 432px;
    @include m {
      max-width: 100%; } }

  &--verify &__container {
    max-width: 420px;
    @include m {
      max-width: 100%; } }

  &--upsell &__container {
    max-width: 706px;
    @include t {
      max-width: 418px; }
    @include m {
      max-width: 100%; } } }

body {
  &.pass-pricing-modal,
  &.session-modal,
  &.full-preview-modal,
  &.show-video,
  &.hire-modal,
  &.upsell-modal {
    overflow: hidden; }

  &.pass-pricing-modal .modal--pass,
  &.session-modal .modal--auth,
  &.full-preview-modal .modal--full-preview,
  &.show-video .modal--video,
  &.hire-modal .modal--hire,
  &.upsell-modal .modal--upsell, {
    opacity: 1;
    pointer-events: all; } }
