.submenu {
  @include m {
    display: flex;
    flex-direction: column;
    min-height: 100%; }

  &__title {
    margin-bottom: 4px;
    @include button-l;
    @include m {
      margin-bottom: 24px;
      @include h5; } }

  &__email {
    margin-bottom: 24px;
    color: $n3;
    line-height: 18px;
    opacity: .75;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include m {
      display: none; } }

  &__nav {
    @include m {
      display: flex;
      margin: 0 -4px; }
    ul {
      @include m {
        flex: 1;
        margin: 0 4px; } }
    li {
      margin-bottom: 24px; }
    a {
      display: flex;
      align-items: center;
      @include base2-medium;
      color: $n3;
      &:hover {
        color: $n1;
        svg {
          fill: $n1; } } }
    svg {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      fill: $n4;
      transition: fill .2s; } }

  &__group {
    margin-top: 32px;
    @include m {
      display: flex;
      margin: 0 -8px; } }

  &__group &__btn {
    @include m {
      flex: 1;
      margin: 0 8px; } }

  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    padding: 0 16px;
    border: 2px solid $n4;
    border-radius: 16px;
    @include button-s;
    color: $n1;
    transition: border-color .2s;
    @include m {
      width: 100%;
      height: 48px;
      border-radius: 8px;
      @include button-l; }
    &:hover {
      border-color: $n1; }
    &--blue {
      background: $blue;
      border-color: $blue;
      color: $n1; } }

  &__mobile {
    display: none;
    @include m {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-top: 32px; } }

  &__browse {
    margin-bottom: 32px; }

  &__socials {
    margin-top: auto; }

  &__copyright {
    margin-top: 16px;
    @include caption2-medium;
    color: $n5; }

  .browse {
    column-count: 2;
    column-gap: 8px; } }
