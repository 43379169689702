.seller {
  position: relative;
  padding: 168px 0 128px;
  @include m {
    padding: 0 0 44px; }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: .12;
    @include m {
      display: none; } }

  &__container {
    position: relative;
    z-index: 2;
    max-width: 912px;
    @include t {
      max-width: 608px; }
    @include m {
      max-width: 100%; } }

  &__head {
    margin-bottom: 80px;
    text-align: center;
    @include m {
      display: none; } }

  &__title {
    margin-bottom: 16px;
    @include h2; }

  &__description {
    @include sub-headline;
    color: $n3; }

  &__auth {
    margin-bottom: 80px;
    @include shadow;
    @include t {
      width: auto; }
    @include m {
      width: auto;
      margin: 0 -24px 40px;
      padding: 110px 24px 40px;
      border-radius: 0; } } }
