.profile {
  &__header {
    position: relative;
    background: $n9; }

  &__background {
    position: absolute;
    inset: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: .05;
    filter: grayscale(1); }

  &__container {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 332px;
    padding-top: 88px;
    padding-bottom: 24px;
    @include m {
      padding-top: 120px; } }

  &__inner {
    display: flex;
    align-items: flex-start;
    margin-bottom: 32px;
    @include m {
      display: block; } }

  &__avatar {
    flex-shrink: 0;
    width: 76px;
    height: 76px;
    margin-right: 16px;
    background-size: 32px 32px;
    border-radius: 50%;
    pointer-events: none;
    @include m {
      margin: 0 0 32px; } }

  &__line {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px; }

  &__name {
    @include h3; }

  &__role {
    margin-left: 8px;
    padding: 2px 6px;
    border: 2px solid $green;
    border-radius: 8px;
    @include link-bold;
    color: $green;
    &--admin {
      border-color: $blue;
      color: $blue; }
    &--follow {
      background: $n6;
      border-color: $n6;
      color: $blue; } }

  &__bio {
    @include body1;
    color: $n3;
    white-space: pre-wrap; }

  &__actions {
    display: flex;
    justify-content: space-between; }

  &__group {
    display: flex;
    align-items: center;
    &:first-child {
      @include t {
        display: none; }
      .btn {
        &.active {
          border-color: $blue;
          color: $n1; }
        span {
          margin-left: 10px;
          color: $n3; } } }
    .btn {
      &:not(:last-child) {
        margin-right: 16px; } } }

  &__body {
    padding: 56px 0 128px;
    @include d {
      padding-top: 32px; }
    @include t {
      padding-top: 24px; } }

  &__message {
    max-width: 540px;
    margin: 0 auto 56px;
    @include h4;
    text-align: center;
    @include d {
      margin-bottom: 32px; }
    @include t {
      margin-bottom: 24px; } }

  &__catalog {
    margin-bottom: 24px; }

  &__foot {
    text-align: center; } }
