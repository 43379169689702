.notifications {
  &__item {
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
      margin-bottom: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $n6; } }

  &__icon {
    flex: 0 0 16px;
    margin: 4px 12px 0 0;
    svg {
      display: block;
      width: 16px;
      height: 16px;
      &.is-logo {
        width: 20px;
        margin-right: -2px;
        height: 17px; } } }

  &__inner {
    flex: 0 0 calc(100% - 28px);
    font-size: 14px;
    line-height: 20px; }

  &__content {
    color: $n3;
    a {
      color: $n1;
      transition: color .2s;
      &:hover {
        text-decoration: underline; } } }

  &__time {
    margin-top: 4px;
    font-size: 13px;
    line-height: 18px;
    color: $n5; } }
