$text-primary: rgba(#F8F8F8, .95);
$text-secondary: rgba(#F8F8F8, .7);
$border: #1D1D1D;

.terms {
  position: relative;
  padding: 136px 0;
  color: $n3;
  @include t {
    padding: 112px 0; }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: calc(50% - 275px);
    width: 550px;
    height: 72px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='550' height='72'%3E%3Cpath fill='none' stroke='%231d1d1d' d='M275 71.5h180.547a53 53 0 0 0 37.476-15.523L675.98-126.977a53 53 0 0 1 37.47-15.523H1031m-756 214H94.453a53 53 0 0 1-37.476-15.523l-182.954-182.954a53 53 0 0 0-37.476-15.523H-481'/%3E%3C/svg%3E");
    @include m {
      display: none; } }
  &__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 48px;
    @include r(1199) {
      padding: 0 32px; }
    @include t {
      padding: 0 24px; } }
  &__head {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 860px;
    margin: 0 auto 56px;
    text-align: center;
    @include r(1199) {
      margin-bottom: 48px; }
    @include t {
      padding: 0 32px; }
    @include m {
      margin-bottom: 36px;
      padding: 0; } }
  &__title {
    font-size: 58px;
    font-weight: 500;
    line-height: 48px;
    color: $text-primary;
    letter-spacing: -0.035em;
    @include m {
      font-size: 32px;
      line-height: 48px; } }
  &__updated {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 6px 12px;
    background: $n10;
    border-radius: 9px;
    font-size: 16px;
    line-height: 24px;
    &:before,
    &:after {
      content: "";
      width: 12px;
      height: 6px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='6'%3E%3Cpath fill='%231d1d1d' d='M9.584 0c.891 0 1.337 1.077.707 1.707L6.705 5.293a1 1 0 0 1-1.414 0L1.705 1.707C1.075 1.077 1.521 0 2.412 0h7.172z'/%3E%3C/svg%3E"); }
    span {
      background: linear-gradient(90deg, #5C5C5C, #ADB7BE, #5C5C5C);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      &:before {
        content: "";
        position: absolute;
        inset: -1px;
        z-index: -1;
        background: linear-gradient(90deg, #1D1D1D, #303030, #1D1D1D);
        border-radius: 10px; } } }
  &__description {
    font-size: 20px;
    line-height: 28px; }
  &__layout {
    position: relative;
    display: flex;
    align-items: start;
    @include t {
      display: block; }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: calc(50% - 50vw);
      width: 100vw;
      height: 1px;
      background: $border;
      @include t {
        top: 96px; }
      @include m {
        top: 80px; } } }
  &__sidebar {
    position: sticky;
    top: 88px;
    z-index: 10;
    max-width: 256px;
    flex: 0 0 256px;
    @include r(1199) {
      top: 72px;
      max-width: 228px;
      flex: 0 0 228px; }
    @include t {
      max-width: 100%;
      background: $n10;
      box-shadow: 0 0 0 4px $n10; } }
  &__nav {
    border: 1px solid $border;
    border-radius: 12px;
    @include t {
      display: flex;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none; } }
    li {
      padding: 4px;
      &:not(:last-child) {
        margin-bottom: -1px;
        border-bottom: 1px solid $border;
        @include m {
          margin-bottom: 0;
          border-bottom: none; } } }
    a {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      height: 48px;
      padding: 0 12px;
      @include link-book;
      color: rgba($n1, .5);
      letter-spacing: 0;
      transition: color .2s;
      @include t {
        gap: 6px;
        height: 40px;
        padding: 0 16px 0 8px; }
      &:hover,
      &.active {
        color: $n1;
        svg {
          fill: $n1; } }
      &.active {
        &:before {
          opacity: 1; } }
      &:before {
        content: "";
        position: absolute;
        inset: 0;
        z-index: -1;
        background: linear-gradient(0deg, rgba(59, 59, 59, 0.2), rgba(59, 59, 59, 0.2)), linear-gradient(180deg, rgba(44, 44, 44, 0.375) 0%, rgba(44, 44, 44, 0.175) 100%);
        box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        opacity: 0;
        transition: opacity .2s; } }
    svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      fill: $n6;
      transition: fill .2s; }
    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; } }
  &__inner {
    flex-grow: 1;
    margin-left: -1px;
    border: solid $border;
    border-width: 0 1px 1px;
    border-radius: 0 0 12px 12px;
    @include t {
      padding-top: 48px; }
    @include m {
      padding-top: 32px;
      border-width: 0; } }
  &__section {
    position: relative;
    padding: 48px 60px;
    font-size: 18px;
    color: $text-secondary;
    line-height: 1.5;
    @include r(1199) {
      padding: 32px; }
    @include t {
      padding: 32px 24px; }
    @include m {
      margin: 0 -24px; }
    &:first-child {
      padding-top: 56px;
      @include r(1199) {
        padding-top: 32px; } }
    &:last-child {
      padding-bottom: 56px;
      @include r(1199) {
        padding-bottom: 32px; } }
    &:not(:last-child) {
      border-bottom: 1px solid $border; }
    strong {
      font-weight: 400;
      color: $text-primary; }
    svg {
      width: 24px;
      height: 24px;
      fill: $text-secondary;
      &.fill-green {
        fill: $green; }
      &.fill-red {
        fill: $red; }
      &.fill-yellow {
        fill: $yellow; } } }
  &__anchor {
    position: absolute;
    top: -88px;
    left: 0;
    height: 1px;
    @include t {
      top: -122px; } }
  &__content {
    h2,
    h3,
    h4 {
      letter-spacing: -0.02em; }
    h2,
    h3,
    h4,
    p,
    ul {
      &:not(:last-child) {
        margin-bottom: 16px; }
      &.mb-24 {
        margin-bottom: 24px; }
      &.mb-48 {
        margin-bottom: 48px;
        @include r(1199) {
          margin-bottom: 32px; } } }
    h2 {
      display: inline-block;
      background: linear-gradient(90deg, #F8F8F8 75%, rgba(19, 22, 53, 0.75) 100%), rgba(248, 248, 248, 0.95);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-size: 36px;
      font-weight: 450;
      line-height: 1.222;
      vertical-align: top; }
    h3 {
      font-size: 24px;
      font-weight: 450;
      color: $text-primary;
      line-height: 1.25; }
    h4 {
      font-size: 18px;
      font-weight: 500;
      color: $text-primary; }
    ul {
      &.blue-dots {
        li {
          &:before {
            background: $blue; } } } }
    li {
      position: relative;
      padding-left: 32px;
      &:before {
        content: "";
        position: absolute;
        top: 9.5px;
        left: 8px;
        width: 8px;
        height: 8px;
        background: $text-secondary;
        border-radius: 4px; }
      &:not(:last-child) {
        margin-bottom: 8px; } }
    a {
      color: $text-primary;
      text-decoration: underline;
      transition: text-decoration-color .2s;
      &:hover {
        text-decoration-color: transparent; } } }
  &__cols {
    position: relative;
    display: flex;
    border: 1px solid $border;
    border-radius: 12px;
    @include m {
      flex-direction: column; }
    &:not(:first-child) {
      margin-top: 24px; }
    &:not(:last-child) {
      margin-bottom: 24px; } }
  &__col {
    position: relative;
    z-index: 1;
    flex: 1;
    padding: 28px;
    @include m {
      padding: 16px; }
    &:before {
      content: "";
      position: absolute;
      inset: 4px;
      z-index: -1;
      background: #151515;
      border: 1px solid $border;
      border-radius: 8px; }
    &:nth-child(1) {
      border-right: 1px solid $border;
      @include m {
        border-right: none; } }
    &:nth-child(2) {
      &:before {
        border-color: rgba($red, .2); } }
    h4 {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 500;
      color: $text-primary; }
    li {
      &:not(:last-child) {
        margin-bottom: 16px; } } }
  &__vectors {
    span {
      &:before,
      &:after {
        content: ""; }
      &:nth-child(1),
      &:nth-child(2) {
        &:before,
        &:after {
          position: absolute;
          z-index: 2;
          width: 14px;
          height: 14px;
          border: solid #303030;
          border-width: 1px 0 0 1px;
          border-radius: 12px 0 0 0; } }
      &:nth-child(1) {
        &:before,
        &:after {
          left: -1px; }
        &:before {
          top: -1px; }
        &:after {
          bottom: -1px;
          transform: rotate(270deg); } }
      &:nth-child(2) {
        &:before,
        &:after {
          right: -1px; }
        &:before {
          top: -1px;
          transform: rotate(90deg); }
        &:after {
          bottom: -1px;
          transform: rotate(180deg); } }
      &:nth-child(n+3) {
        position: absolute;
        left: calc(50% - 5px);
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 10px;
        height: 5px;
        @include m {
          display: none; }
        &:before,
        &:after {
          background: #303030; }
        &:before {
          width: 10px;
          height: 1px; }
        &:after {
          width: 1px;
          height: 5px; } }
      &:nth-child(3) {
        top: -1px; }
      &:nth-child(4),
      &:nth-child(6) {
        bottom: -1px;
        transform: rotate(180deg); } } }
  &__cols--passes {
    font-size: 16px;
    h4 {
      margin-bottom: 24px;
      font-size: 22px;
      line-height: 1;
      opacity: .95; }
    li {
      &.has-icon {
        svg {
          &:first-child {
            top: 0;
            left: -4px; } } } } }
  &__cols--passes &__col {
    padding: 24px;
    &:nth-child(2) {
      border-right: 1px solid $border;
      &:before {
        border-color: $border; } } }
  &__cols--passes &__vectors {
    span {
      &:nth-child(3),
      &:nth-child(4) {
        left: calc(33.33% - 5px); }
      &:nth-child(5),
      &:nth-child(6) {
        left: calc(66.66% - 5px); } } }
  &__contacts {
    display: flex;
    border: 1px solid $border;
    border-radius: 12px;
    li {
      flex: 1;
      padding: 4px;
      &:first-child {
        border-right: 1px solid $border; } }
    a {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 75px;
      background: #151515;
      border: 1px solid $border;
      border-radius: 8px;
      color: $text-primary;
      transition: background .2s, border-color .2s;
      &:hover {
        background: transparent;
        border-color: transparent;
        &:before {
          opacity: 1; } }
      &:before {
        content: "";
        position: absolute;
        inset: -1px;
        background: linear-gradient(0deg, rgba(59, 59, 59, 0.2), rgba(59, 59, 59, 0.2)), linear-gradient(180deg, rgba(44, 44, 44, 0.375) 0%, rgba(44, 44, 44, 0.175) 100%);
        box-shadow: inset 0px 1px 1px rgba(255, 255, 255, 0.05);
        border-radius: 8px;
        opacity: 0;
        transition: opacity .2s; } }
    span {
      position: relative;
      z-index: 2; } }
  &__lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    @include m {
      display: none; }
    li {
      position: absolute;
      top: 0;
      width: 216px;
      height: 1444px;
      &:before {
        content: "";
        position: absolute;
        top: 400px;
        width: 1px;
        height: calc(100% - 894px);
        background: $border; }
      span {
        position: absolute;
        top: 0;
        width: 216px;
        height: 400px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='216' height='400' viewBox='0 0 216 400'%3E%3Cpath fill='none' stroke='%231D1D1D' d='M215.5,400 L215.5,142.453 C215.5,128.397 209.916,114.916 199.977,104.977 L17.0233,-77.9767 C7.08391,-87.9161 1.5,-101.397 1.5,-115.453 L1.5,-433'/%3E%3C/svg%3E"); }
      &:first-child {
        right: calc(50% + 551px);
        @include r(1199) {
          right: calc(100% - 33px); }
        @include t {
          right: calc(100% - 24px); }
        &:before {
          right: 0; }
        &:after {
          content: "";
          position: absolute;
          right: 0;
          bottom: 0;
          width: 214px;
          height: 494px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='214' height='494' viewBox='0 0 214 494'%3E%3Cdefs%3E%3ClinearGradient id='a' x1='50%25' x2='50%25' y1='0%25' y2='100%25'%3E%3Cstop offset='56.806%25' stop-color='%231D1D1D'/%3E%3Cstop offset='100%25' stop-color='%231D1D1D' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpath fill='none' stroke='url(%23a)' d='M214,0 L214,257.547 C214,271.603 208.416,285.084 198.477,295.023 L0,493.5' transform='translate(-.5)'/%3E%3C/svg%3E"); }
        span {
          right: 0; } }
      &:last-child {
        left: calc(50% + 551px);
        @include r(1199) {
          left: calc(100% - 33px); }
        @include t {
          left: calc(100% - 25px); }
        &:before {
          left: 0; }
        span {
          left: 0;
          transform: scaleX(-1); } } } }

  &-example {
    li {
      display: flex;
      gap: 16px;
      @include m {
        gap: 8px;
        flex-direction: column;
        padding: 12px 12px 12px 44px;
        background: #151515;
        border: 1px solid $border;
        border-radius: 8px;
        &:before {
          top: 22px;
          left: 20px; } } }
    span {
      flex-basis: 354px;
      @include m {
        flex-basis: unset; } }
    strong {
      @include m {
        margin-left: -32px; } } }

  &-alert {
    position: relative;
    z-index: 1;
    padding: 28px 92px 28px 28px;
    border: 1px solid $border;
    border-radius: 12px;
    font-weight: 450;
    color: $text-primary;
    @include m {
      padding: 28px; }
    &:before {
      content: "";
      position: absolute;
      inset: 4px;
      z-index: -1;
      background: #151515;
      border: 1px solid rgba($red, .2);
      border-radius: 8px; }
    svg {
      position: absolute;
      top: 28px;
      right: 28px;
      @include m {
        display: none; } } }

  .has-icon {
    position: relative;
    padding-left: 32px;
    svg {
      &:first-child {
        position: absolute;
        top: 1.5px;
        left: 0; } } } }
