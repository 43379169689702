.faq {
  max-width: 840px;
  margin: 0 auto;

  &__title {
    margin-bottom: 48px;
    @include h4;
    text-align: center;
    @include m {
      margin-bottom: 16px;
      @include h5;
      text-align: left; } }

  &__item {
    margin-bottom: -1px;
    border: solid $n7;
    border-width: 1px 0; }

  &__head {
    position: relative;
    padding: 24px 40px 24px 0;
    @include h6;
    cursor: pointer;
    @include m {
      padding: 16px 32px 16px 0;
      @include button-l; }
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 35px;
      right: 0;
      width: 16px;
      height: 2px;
      background: $n5;
      border-radius: 2px;
      transition: background .2s, transform .2s;
      @include m {
        top: 27px; } }
    &:after {
      transform: rotate(90deg); }
    &:hover {
      &:before,
      &:after {
        background: $n1; } }
    &.active {
      &:after {
        transform: rotate(0deg); } } }

  &__body {
    display: none;
    padding-bottom: 24px;
    @include body1;
    color: $n3;
    @include m {
      @include base1; } } }
