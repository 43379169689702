.ppage {
  min-height: 100vh;
  padding-bottom: 128px;
  @include d {
    padding-bottom: 80px; }
  @include t {
    padding-bottom: 0; }

  &__video {
    position: relative;
    margin-bottom: 24px;
    padding-bottom: 56.25%;
    @include d {
      margin: 0 -28px 8px; }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; } }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px 104px;
    @include d {
      margin: 0 -28px 80px; }
    @include t {
      display: block;
      margin: 0 -16px 80px; }
    @include m {
      margin: 0 -16px 40px; }
    li {
      position: relative;
      flex: 0 0 calc(50% - 24px);
      margin: 0 12px 24px;
      border-radius: 24px;
      overflow: hidden;
      @include d {
        flex: 0 0 calc(50% - 8px);
        margin: 0 4px 8px;
        border-radius: 8px; }
      @include t {
        margin: 0 0 8px; }
      &:before {
        content: "";
        display: block;
        padding-bottom: calc((1020 / 1350) * 100%); } }
    img,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }

  &__pass {
    display: flex;
    align-items: center;
    margin: -80px 0 104px;
    padding: 18px;
    background: $n8;
    border-radius: 40px;
    @include base1;
    color: $green;
    @include d {
      margin: -56px 0 80px; }
    @include m {
      display: none; }
    .btn {
      margin-left: auto; } }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin-right: 16px;
    border: 2px solid $n5;
    border-radius: 50%; }

  &__row {
    display: flex;
    max-width: 1040px;
    margin: 0 auto 128px;
    @include d {
      margin-bottom: 80px; }
    @include m {
      display: block;
      margin-bottom: 40px; }
    .formats {
      li {
        box-shadow: 0 0 0 2px $n10; } } }

  &__col {
    &:first-child {
      flex: 0 0 530px;
      max-width: 530px;
      margin-right: 100px;
      @include d {
        margin-right: 80px; }
      @include t {
        flex: 0 0 400px;
        max-width: 400px;
        margin-right: 32px; }
      @include m {
        max-width: 100%;
        margin: 0 0 40px; } }
    &:last-child {
      flex: 0 0 calc(100% - 630px);
      @include d {
        flex: 0 0 calc(100% - 610px); }
      @include t {
        flex: 0 0 calc(100% - 432px); } } }

  &__title {
    margin-bottom: 16px;
    @include h4; }

  &__content {
    @include body1;
    color: $n3;
    word-wrap: break-word;
    a {
      color: $n1;
      &:hover {
        text-decoration: underline; } } }

  &__highlights {
    margin-bottom: 64px;
    @include m {
      margin-bottom: 40px; }
    li {
      position: relative;
      margin-bottom: 16px;
      padding-left: 32px;
      @include base1;
      color: $n3;
      &:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 20px;
        height: 20px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='none'%3E%3Cpath fill-rule='evenodd' d='M10.001.833A9.17 9.17 0 0 0 .834 10a9.17 9.17 0 0 0 9.167 9.167A9.17 9.17 0 0 0 19.167 10 9.17 9.17 0 0 0 10.001.833zm4.339 7.256c.325-.325.325-.853 0-1.179s-.853-.325-1.178 0l-4.411 4.411L6.84 9.411c-.325-.325-.853-.325-1.179 0s-.325.853 0 1.178l2.5 2.5c.325.325.853.325 1.179 0l5-5z' fill='%2300A656'/%3E%3C/svg%3E"); } } }

  &__files {
    display: flex;
    align-items: center;
    margin-top: 64px;
    @include base1;
    color: $n4;
    @include m {
      margin-top: 40px; }
    svg {
      margin-right: 10px;
      fill: $n4; }
    span {
      margin-right: 4px; } } }
