.tools {
  display: flex;
  margin: -8px;

  &__item {
    flex: 1;
    height: 220px;
    margin: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 16px;
    transition: box-shadow .2s;
    &:hover {
      box-shadow: 0 0 0 4px $blue; } } }
