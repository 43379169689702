@mixin h1 {
  font-size: 64px;
  font-weight: 500;
  line-height: 64px; }

@mixin h2 {
  font-size: 44px;
  font-weight: 450;
  line-height: 48px; }

@mixin h3 {
  font-size: 34px;
  font-weight: 450;
  line-height: 40px; }

@mixin h4 {
  font-size: 28px;
  font-weight: 450;
  line-height: 40px; }

@mixin h5 {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px; }

@mixin h6 {
  font-size: 19px;
  font-weight: 500;
  line-height: 24px; }

@mixin h6-book {
  font-size: 19px;
  font-weight: 450;
  line-height: 24px; }

@mixin sub-headline {
  font-size: 20px;
  line-height: 28px; }

@mixin body1 {
  font-size: 18px;
  line-height: 28px; }

@mixin body2 {
  font-size: 17px;
  font-weight: 450;
  line-height: 21px; }

@mixin base1 {
  font-size: 16px;
  line-height: 24px; }

@mixin base1-bold {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; }

@mixin base2 {
  font-size: 15px;
  line-height: 20px; }

@mixin base2-medium {
  font-size: 15px;
  font-weight: 450;
  line-height: 20px; }

@mixin button-l {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px; }

@mixin button-m {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px; }

@mixin button-s {
  font-size: 13px;
  font-weight: 450;
  line-height: 24px; }

@mixin button-xs {
  font-size: 12px;
  font-weight: 500;
  line-height: 24px; }

@mixin link {
  font-size: 15px;
  font-weight: 450;
  line-height: 18px; }

@mixin link-book {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px; }

@mixin link-bold {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; }

@mixin caption1 {
  font-size: 13px;
  line-height: 20px; }

@mixin caption1-book {
  font-size: 13px;
  font-weight: 450;
  line-height: 14px; }

@mixin caption1-medium {
  font-size: 13px;
  font-weight: 450;
  line-height: 14px; }

@mixin caption2 {
  font-size: 12px;
  line-height: 15px; }

@mixin caption2-medium {
  font-size: 12px;
  font-weight: 450;
  line-height: 16px; }
