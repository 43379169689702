.upsell {
  display: flex;
  padding-left: 288px;
  background: $n7;
  border-radius: 24px;
  @include t {
    padding: 0; }

  &__preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 288px;
    height: 100%;
    @include t {
      display: none; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 24px 0 0 24px; } }

  &__inner {
    padding: 40px 40px 32px;
    @include m {
      padding: 0; } }

  &__title {
    margin-bottom: 16px;
    @include h4; }

  &__content {
    margin-bottom: 32px;
    @include body1;
    color: $n3;
    strong {
      font-weight: 400;
      color: $n1; } }

  &__time {
    display: flex;
    align-items: center;
    margin: -16px 0 32px;
    @include button-s;
    color: $green;
    svg {
      margin-right: 10px; } }

  &__foot {
    margin-top: 24px;
    text-align: center;
    a {
      @include button-s;
      color: $n1;
      &:hover {
        text-decoration: underline; } } } }
