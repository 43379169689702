$products-speed: 160s;

.products {
  position: relative;
  z-index: 10;
  margin: -8px 0 -20px;
  padding: 20px 0;
  overflow: hidden;
  @include m {
    display: none; }
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    z-index: 6;
    width: 72px;
    height: 100%;
    background: linear-gradient(to right, black, transparent); }
  &:before {
    left: 0; }
  &:after {
    right: 0;
    transform: rotate(180deg); }
  &__group {
    position: relative;
    height: 238px;
    margin-bottom: 12px;
    &:hover {
      z-index: 2; } }
  &__group:nth-child(2) &__list {
    animation: products3 $products-speed linear infinite;
    &:last-child {
      animation: products4 $products-speed linear infinite; } }
  &__list {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 3280px;
    animation: products1 $products-speed linear infinite;
    &:hover {
      z-index: 2; }
    a {
      position: relative;
      flex: 0 0 316px;
      width: 316px;
      margin-right: 12px;
      border-radius: 8px;
      transition: box-shadow .2s, transform .2s;
      &:hover {
        z-index: 4;
        box-shadow: 0px 32px 48px 16px rgba(0, 0, 0, 0.5);
        transform: scale(1.164); } }
    img {
      border-radius: 8px; }
    &:last-child {
      animation: products2 $products-speed linear infinite; } } }

@keyframes products1 {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(-100%); } }

@keyframes products2 {
  0% {
    transform: translateX(100%); }
  100% {
    transform: translateX(0%); } }

@keyframes products3 {
  0% {
    transform: translateX(0); }
  100% {
    transform: translateX(100%); } }

@keyframes products4 {
  0% {
    transform: translateX(-100%); }
  100% {
    transform: translateX(0%); } }
