.product {
  &__preview {
    position: relative;
    background: $n9;
    border-radius: 16px 16px 0 0; }
  &__preview:hover &__counter,
  &__preview:hover &__wrap,
  &__preview:hover &__btn:not(.active) {
    @include mouse {
      opacity: 1; } }
  &__preview:hover &__btn.active {
    @include mouse {
      background: transparent;
      border-color: $n5;
      &:hover {
        border-color: $n1; } } }
  &__preview:hover &__over {
    @include mouse {
      background: rgba($n9,.85); } }

  &__media {
    aspect-ratio: 1.4830;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px 16px 0 0;
    video {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 16px 16px 0 0;
      object-fit: cover; } }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    margin: 0 8px;
    border: 2px solid $n5;
    border-radius: 50%;
    transition: background .2s, border-color .2s, opacity .2s;
    &:not(.active) {
      opacity: 0; }
    &:hover {
      border-color: $n1;
      svg {
        fill: $n1; } }
    svg {
      width: 24px;
      height: 24px;
      fill: $n3;
      transition: fill .2s; }
    path {
      transition: stroke .2s; } }

  &__like {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 4;
    display: flex;
    align-items: center;
    pointer-events: none;
    @include touch {
      display: none; } }
  &__like &__btn {
    margin-right: 0;
    pointer-events: all;
    svg {
      fill: transparent; }
    path {
      stroke: $n3; }
    &:hover {
      svg {
        fill: transparent; }
      path {
        stroke: $n1; } }
    &.active {
      background-color: rgba($n9,.9);
      border-color: transparent;
      svg {
        fill: $red; }
      path {
        stroke: $red; } } }

  &__counter {
    @include caption1-medium;
    color: $n3;
    opacity: 0;
    transition: opacity .2s; }

  &__over {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: -1px -1px 0;
    background: transparent;
    border-radius: 16px 16px 0 0;
    transition: background .2s; }
  &__over &__btn {
    position: relative;
    z-index: 4;
    @include touch {
      display: none; } }

  &__link {
    position: absolute;
    inset: 0; }

  &__inner {
    position: relative;
    padding: 12px 16px 16px;
    background: $n9;
    border-radius: 0 0 16px 16px; }

  &__head {
    display: flex;
    margin-bottom: 8px;
    @include base2-medium;
    color: $n1; }

  &__title {
    min-width: 0;
    margin-right: 24px;
    color: $n1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

  &__price {
    display: flex;
    margin-left: auto; }

  &__full {
    margin-right: 8px;
    color: $n3;
    text-decoration: line-through; }

  &__sale {
    background: linear-gradient(to right, #FF78FA 20%, #FFB36C 40%, #FFB36C 60%, #FF78FA 80%);
    background-size: 200% auto;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: product-sale 2s linear infinite; }

  &__meta {
    display: flex;
    align-items: center;
    @include caption1-medium;
    transition: opacity .2s;
    a {
      display: block;
      color: $n3;
      transition: color .2s;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        color: $n1; } } }

  &__author,
  &__category {
    min-width: 0; }

  &__author {
    display: flex;
    align-items: center; }

  &__avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    background-color: $blue;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%; }

  &__arrow {
    flex-shrink: 0;
    width: 6px;
    height: 8px;
    margin: 0 8px;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.5C0 0.675955 0.940764 0.205573 1.6 0.7L4.93333 3.2C5.46667 3.6 5.46667 4.4 4.93333 4.8L1.6 7.3C0.940764 7.79443 0 7.32405 0 6.5V1.5Z' fill='%234D4D4D'/%3E%3C/svg%3E%0A"); }

  &__category {
    margin-right: 8px; }

  &__featured {
    flex-shrink: 0;
    margin-left: auto;
    pointer-events: none;
    &:before {
      content: "";
      display: block;
      position: relative;
      z-index: 2;
      width: 24px;
      height: 24px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'%3E%3Cpath fill-rule='evenodd' d='M2.031 12c0-.134-.027-.268-.079-.393L1.231 9.86C1.079 9.492 1 9.097 1 8.699s.078-.793.231-1.161.376-.703.658-.984.616-.505.984-.657l1.744-.722c.253-.104.454-.305.559-.557l.723-1.744c.308-.743.898-1.333 1.641-1.641s1.578-.308 2.321 0l1.743.722c.253.104.537.104.79-.001l.002-.001 1.745-.72c.743-.307 1.578-.307 2.321 0s1.333.898 1.641 1.641l.705 1.703.018.041a1.03 1.03 0 0 0 .558.559l1.745.723c.743.308 1.334.898 1.641 1.641s.308 1.578 0 2.321l-.722 1.744c-.053.127-.079.262-.079.396a1.03 1.03 0 0 0 .079.395l.722 1.744c.308.743.308 1.578 0 2.321s-.898 1.334-1.641 1.641l-1.745.723c-.253.105-.453.306-.558.559l-.018.041-.705 1.703a3.03 3.03 0 0 1-1.641 1.641c-.743.308-1.578.308-2.321 0l-1.745-.72-.002-.001c-.253-.105-.537-.105-.79-.001l-1.743.722a3.03 3.03 0 0 1-2.321 0c-.743-.308-1.333-.898-1.641-1.641l-.723-1.744c-.105-.252-.307-.453-.559-.557l-1.744-.722c-.368-.152-.702-.376-.984-.657s-.505-.616-.658-.984S1 15.7 1 15.301s.079-.793.231-1.161l.721-1.747c.052-.126.079-.26.079-.393zm14.176-1.793a1 1 0 0 0-1.414-1.414L11 12.586l-1.293-1.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4.5-4.5z' fill='%2300a656'/%3E%3C/svg%3E");
      pointer-events: all; }
    &:hover {
      span {
        opacity: 1; } }
    span {
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 32px;
      padding-right: 36px;
      background: rgba($n9,.9);
      color: $green;
      opacity: 0;
      transition: opacity .2s; } } }

@keyframes product-sale {
  to {
    background-position: 200% center; } }
