.search {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: $n10;
  overflow: auto;

  &__head {
    position: sticky;
    top: 0;
    z-index: 50;
    padding-bottom: 40px;
    background: rgba($n10,.98); }
  &__head &__container {
    position: relative; }

  &__field {
    position: relative; }

  &__input {
    width: 100%;
    height: 116px;
    padding-top: 8px;
    background: none;
    font-size: 34px;
    font-weight: 450;
    color: $n1;
    caret-color: $blue;
    -webkit-appearance: textfield;
    @include t {
      height: 78px;
      padding: 0 0 4px;
      font-size: 28px; }
    &::placeholder {
      color: $n5; }
    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration {
      appearance: none; } }

  &__value {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    padding-top: 8px;
    pointer-events: none;
    @include t {
      padding: 0 0 4px; }
    span {
      font-size: 34px;
      font-weight: 450;
      letter-spacing: 0;
      color: transparent;
      @include t {
        font-size: 28px; } }
    button {
      position: relative;
      top: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;
      background: $n6;
      border-radius: 14px;
      pointer-events: auto;
      &:hover {
        svg {
          fill: $n1; } } }
    svg {
      width: 12px;
      height: 12px;
      fill: $n3;
      transition: fill .2s; } }

  &__sale {
    position: absolute;
    top: 50px;
    right: 108px;
    color: $n3;
    @include d {
      right: 100px; }
    @include t {
      top: 27px;
      right: 72px; }
    &.active {
      color: $green;
      &:before {
        background: $green; }
      &:after {
        border-color: $n5; } }
    &:before {
      background: $n3; }
    &:after {
      border-color: $n6; } }

  &__close {
    position: absolute;
    top: 40px;
    right: 40px;
    width: 44px;
    height: 44px;
    border: 2px solid rgba($n1,.1);
    border-radius: 50%;
    font-size: 0;
    transition: border-color .2s;
    @include d {
      right: 32px; }
    @include t {
      top: 23px;
      right: 24px;
      width: 32px;
      height: 32px;
      border-color: $n3; }
    &:hover {
      border-color: $n1; }
    svg {
      width: 14px;
      height: 14px;
      fill: $n3;
      @include t {
        width: 12px;
        height: 12px; } } }

  &__empty {
    position: relative; }

  &__placeholders {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px;
    li {
      flex: 0 0 calc(25% - 24px);
      margin: 0 12px 24px;
      overflow: hidden;
      @include d {
        flex: 0 0 calc(33.33% - 24px); }
      @include t {
        flex: 0 0 calc(50% - 24px); }
      @include m {
        flex: 0 0 calc(100% - 24px); }
      &:before,
      &:after {
        content: "";
        display: block; }
      &:before {
        aspect-ratio: 1.4830;
        background: #141414;
        border-radius: 16px 16px 0 0; }
      &:after {
        height: 80px;
        background: #131313;
        border-radius: 0 0 16px 16px; } } }

  &__alert {
    position: absolute;
    top: 140px;
    left: calc(50% - 210px);
    width: 420px;
    padding: 40px;
    background: $n7;
    border-radius: 24px;
    @include shadow;
    @include m {
      left: 0;
      width: 100%; } }

  &__title {
    margin-bottom: 16px;
    @include h4; }

  &__text {
    @include body1;
    color: $n3; }

  &__catalog {
    margin-bottom: 0; }

  .loader {
    height: 80px;
    &.loading {
      height: calc(100vh - 200px); }
    &.faded {
      opacity: 0; } } }
