.verify {
  padding: 40px 40px 32px;
  background: $n7;
  border-radius: 24px;
  @include shadow;
  @include m {
    padding: 0;
    box-shadow: none; }

  &__title {
    margin-bottom: 16px;
    @include h4; }

  &__content {
    margin-bottom: 26px;
    @include body1;
    color: $n3; }

  .field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    &__input {
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; } } }

  &__foot {
    margin-top: 28px;
    @include caption1-book;
    color: $n3;
    text-align: center;
    a {
      @include button-s;
      color: $n1; } } }
