.spage {
  display: flex;
  position: relative;
  min-height: 100vh;
  padding: 200px 0;

  &__background {
    position: absolute;
    inset: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: .12; } }

  &__container {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 440px;
    margin: auto;
    text-align: center;
    .btn {
      display: inline-flex;
      width: auto; } }

  &__logo {
    position: relative;
    right: -3px;
    margin: 0 auto 48px; }

  &__title {
    margin-bottom: 16px;
    @include h2; }

  &__content {
    max-width: 340px;
    margin: 0 auto 48px;
    @include body1;
    color: $n3; }

  &__copyright {
    position: absolute;
    left: 0;
    bottom: 48px;
    width: 100%;
    @include caption2-medium;
    color: $n5;
    text-align: center;
    z-index: 2; } }
