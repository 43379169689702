.payup {
  position: relative;
  overflow: hidden;
  @include m {
    margin: 0;
    padding-bottom: 72px; }

  &__empty {
    padding: 14px 24px;
    background: $n6;
    border-radius: 24px;
    @include link-book;
    white-space: nowrap;
    @include m {
      padding: 0;
      background: none; } }

  &__layout {
    width: 630px;
    background: $n7;
    border-radius: 24px;
    @include m {
      width: 100%;
      border-radius: 0; } }
  &__layout.step-checkout &__sidebar {
    @include m {
      display: none; } }
  &__layout.step-checkout &__container {
    @include m {
      display: flex; } }

  &__sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 242px;
    height: 100%;
    padding: 16px;
    background: $n6;
    border-radius: 24px 0 0 24px;
    @include hide-scrollbar;
    @include m {
      position: static;
      width: 100%;
      height: auto;
      padding: 0;
      background: none;
      border-radius: 0; } }
  &__sidebar &__title {
    display: none;
    @include m {
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px; } }
  &__sidebar &__foot {
    display: none;
    @include m {
      display: block; } }

  &__product {
    position: relative;
    margin-bottom: 16px;
    @include m {
      margin-bottom: 24px;
      padding-right: calc(100% - 242px); } }

  &__preview {
    position: relative;
    height: 146px;
    margin-bottom: 8px;
    background: $n11;
    border-radius: 16px;
    @include m {
      height: 168px; }
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: cover;
      transition: opacity .2s; } }
  &__preview:hover {
    img {
      opacity: .1;
      @include m {
        opacity: 1; } } }
  &__preview:hover &__over {
    opacity: 1; }

  &__over {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    opacity: 0;
    transition: opacity .2s;
    @include m {
      display: none; }
    button {
      width: 40px;
      height: 40px;
      border: 2px solid rgba($n1,.1);
      border-radius: 50%;
      font-size: 0;
      transition: border-color .2s;
      &:hover {
        border-color: $n1; } } }

  &__name {
    @include base2-medium;
    color: $n1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

  &__meta {
    display: flex;
    align-items: baseline; }

  &__price {
    margin-right: 8px;
    @include base2-medium; }

  &__full {
    margin-right: 8px;
    color: $n3;
    text-decoration: line-through; }

  &__details {
    @include caption1-book;
    color: $n3; }

  &__remove {
    display: none;
    @include m {
      display: block;
      position: absolute;
      top: 68px;
      right: 0;
      svg {
        display: block;
        fill: $n5; } } }

  &__placeholder {
    margin-bottom: 16px;
    opacity: 0.1;
    @include m {
      display: none; }
    svg {
      display: block; } }

  &__container {
    display: flex;
    flex-direction: column;
    // min-height: 576px
    min-height: 504px;
    padding: 20px 24px 24px 266px;
    &.is-signed {
      min-height: 504px;
      @include m {
        min-height: 100%; } }
    @include m {
      display: none;
      min-height: 100%;
      padding: 0; } }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }

  &__title {
    @include h5;
    color: $n1;
    @include m {
      display: flex;
      align-items: center;
      height: 32px; } }

  &__back {
    display: none;
    @include m {
      display: block;
      width: 32px;
      height: 32px;
      margin-right: 12px;
      svg {
        fill: $n3; } } }

  &__tabs {
    position: relative;
    display: flex;
    margin-left: auto;
    &.active {
      &:before {
        transform: translateX(62px); } }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 10px;
      width: 52px;
      height: 32px;
      border: 2px solid $blue;
      border-radius: 6px;
      transition: transform .4s;
      pointer-events: none; }
    button {
      width: 52px;
      height: 32px;
      margin-left: 10px;
      background: $n8;
      border: 2px solid $n8;
      border-radius: 6px;
      font-size: 0;
      transition: border-color .2s;
      &:hover {
        border-color: $n5; } }
    svg {
      fill: $n1; } }

  &__error {
    position: relative;
    margin: -10px 0 30px;
    padding: 16px;
    background: $n8;
    border-radius: 8px;
    color: $n3;
    text-align: center;
    cursor: pointer;
    &:hover {
      span {
        color: $n1; } }
    span {
      text-decoration: underline;
      transition: color .2s; }
    svg {
      position: absolute;
      top: -12px;
      left: 16px; } }

  &__msg {
    margin: auto 0 24px;
    @include base2;
    color: $n3;
    letter-spacing: -0.04em;
    text-align: center;
    p {
      margin: 0; }
    a {
      font-weight: 500;
      color: $n1;
      &:hover {
        text-decoration: underline; } } }

  &__foot {
    @include m {
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 50;
      width: 100%;
      padding: 24px 24px 40px;
      background: rgba($n9,.98); } }

  &__btn {
    width: 100%;
    height: 48px;
    background: $blue;
    border-radius: 8px;
    @include button-l;
    color: $n1;
    transition: background .2s, color .2s;
    &:hover {
      background: $n1;
      color: $n7; } }

  &__hint {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    @include caption1;
    color: $n4;
    @include m {
      margin: 0; }
    svg {
      margin-right: 4px;
      fill: $n4; } }

  .field {
    margin-bottom: 30px;
    &--password {
      margin-bottom: 24px; } } }
