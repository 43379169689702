.layout {
  display: flex;
  @include t {
    display: block; }

  &__sidebar {
    flex: 0 0 228px;
    margin-right: 48px;
    @include t {
      position: sticky;
      top: 72px;
      z-index: 4;
      display: flex;
      margin: 0 -24px 24px;
      padding-bottom: 24px;
      transition: background .2s;
      overflow: auto;
      @include hide-scrollbar;
      &:before,
      &:after {
        content: "";
        flex: 0 0 16px; }
      &.scrolled {
        background: rgba($n9,.98); } }
    @include m {
      margin-bottom: 8px; } }

  &__nav {
    position: sticky;
    top: 118px;
    padding: 24px;
    background: $n7;
    border-radius: 16px;
    @include t {
      display: flex;
      flex-shrink: 0;
      position: static;
      padding: 0;
      background: none;
      border-radius: 0; } }

  &__link {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    height: 44px;
    padding: 0 14px;
    background: none;
    border: 2px solid $n6;
    border-radius: 22px;
    @include link-bold;
    color: $n3;
    transition: border-color .2s, color .2s;
    @include t {
      width: auto;
      margin: 0 8px;
      @include base1-bold; }
    &:not(:last-child) {
      margin-bottom: 16px;
      @include t {
        margin: 0 8px; } }
    &:hover {
      border-color: $n1; }
    &.active {
      border-color: $blue;
      color: $n1; } }

  &__inner {
    flex: 0 0 calc(100% - 276px); }

  &--static &__sidebar {
    @include t {
      position: static; } }

  &--small {
    max-width: 736px;
    margin: 0 auto;
    @include t {
      max-width: 100%; } } }
