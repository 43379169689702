// colors
$blue: #2D68FF;
$green: #00A656;
$red: #FF5A43;
$pink: #FF9393;
$yellow: #FFC530;

// neutral
$n1: #FFFFFF;
$n2: #F8F8F8;
$n3: #ADB7BE;
$n4: #5C5C5C;
$n5: #4D4D4D;
$n6: #2C2C2C;
$n7: #262626;
$n8: #1D1C1E;
$n9: #181818;
$n10: #121212;
$n11: #000000;

$new-bg: #171718;
