.socials {
  display: flex;
  margin: 0 -12px;

  li {
    margin: 0 12px; }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border: 2px solid $n6;
    border-radius: 50%;
    transition: border-color .2s;
    &:hover {
      border-color: $n1;
      svg {
        fill: $n1; } }

    svg {
      width: 24px;
      height: 24px;
      fill: $n3;
      transition: fill .2s; } } }
