.related {
  max-width: 1040px;
  margin: 128px auto 0;
  @include d {
    display: none; }

  &__head {
    display: flex;
    align-items: center;
    margin-bottom: 40px; }

  &__avatar {
    flex-shrink: 0;
    width: 58px;
    height: 58px;
    margin-right: 16px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover; } }

  &__title {
    @include h4;
    a {
      color: $n1; } }

  &__btns {
    display: flex;
    margin-left: auto;
    .btn {
      margin-left: 16px; } } }
