.alerts {
  position: fixed;
  top: 74px;
  left: calc(50vw - 664px);
  z-index: 999999;
  width: 384px;
  min-height: 76px;
  padding: 22px 78px 22px 22px;
  background: $blue;
  border: 2px solid $blue;
  border-radius: 24px;
  @include body1;
  color: $n1;
  opacity: 0;
  transform: scale(0);
  transform-origin: 18px 0;
  transition: opacity .4s, transform .4s;
  pointer-events: none;
  cursor: pointer;
  @include dmax {
    left: 40px; }
  @include d {
    left: 32px; }
  @include t {
    left: 24px;
    max-width: calc(100vw - 48px); }
  &:hover {
    svg {
      opacity: 1; } }
  &.fadeIn {
    opacity: 1;
    transform: scale(1);
    pointer-events: all; }
  &.error {
    background: $n7;
    border-color: $pink; }
  svg {
    position: absolute;
    top: 14px;
    right: 14px;
    fill: $n1;
    opacity: .5;
    transition: opacity .2s; } }
