.lib {
  max-width: 1040px;
  margin: 0 auto 88px;
  @include t {
    margin-bottom: 104px; }
  @include m {
    display: none; }

  &__title {
    margin-bottom: 80px;
    @include h3;
    text-align: center; }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
    @include t {
      margin: -12px; } }

  &__item {
    flex: 0 0 calc(33.33% - 40px);
    margin: 0 20px 40px;
    @include t {
      flex: 0 0 calc(50% - 24px);
      margin: 0 12px 24px; } }

  &__link {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;
    background: $n8;
    border: 4px solid $n8;
    border-radius: 24px;
    color: $n3;
    transition: border-color .2s;
    &:hover {
      border-color: $blue; } }
  &__link:hover &__icon {
    svg {
      fill: $blue; } }

  &__icon {
    position: absolute;
    top: 32px;
    left: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 32px;
      height: 32px;
      fill: $n6;
      transition: fill .2s; } }

  &__value {
    margin-bottom: 10px;
    @include h1;
    color: $n1; }

  &__name {
    @include base2-medium; }

  &__foot {
    position: absolute;
    left: 32px;
    bottom: 32px;
    right: 32px;
    display: flex;
    align-items: center; }

  &__caption {
    display: flex;
    align-items: center;
    @include caption1-medium;
    color: $n5;
    &:after {
      content: "";
      width: 6px;
      height: 8px;
      margin-left: 6px;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='8' viewBox='0 0 6 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1.5C0 0.675955 0.940764 0.205573 1.6 0.7L4.93333 3.2C5.46667 3.6 5.46667 4.4 4.93333 4.8L1.6 7.3C0.940764 7.79443 0 7.32405 0 6.5V1.5Z' fill='%234D4D4D'/%3E%3C/svg%3E%0A"); } }

  .formats {
    margin-left: auto;
    li {
      display: flex;
      align-items: center;
      justify-content: center; } } }
