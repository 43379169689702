.hide-tablet-wide {
  @include d {
    display: none !important; } }

.hide-tablet {
  @include t {
    display: none !important; } }

.hide-mobile {
  @include m {
    display: none !important; } }
