.boost {
  position: relative;
  background: $new-bg;
  border-radius: 16px;

  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
    opacity: .08;
    filter: grayscale(1); }

  &__inner {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 462px;
    margin-top: 4px;
    padding: 48px;
    text-align: center; }

  &__title {
    margin-bottom: 12px;
    @include h2; }

  &__text {
    margin-bottom: 52px;
    @include sub-headline;
    color: $n3; }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 240px;
    height: 64px;
    background: $blue;
    border-radius: 32px;
    @include base1-bold;
    color: $n1;
    transition: background .2s, color .2s;
    &:hover {
      background: $n1;
      color: $n8; } } }
