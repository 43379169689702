.note {
  position: relative;
  max-width: 736px;
  margin: 0 auto 24px;
  padding: 32px;
  background: $blue;
  border-radius: 24px;
  @include body1;
  color: $n1;
  &__close {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 0;
    transition: opacity .2s;
    &:hover {
      opacity: .5; } } }
